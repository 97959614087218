import { Card, Image, Text, Badge, Button, Group } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import GoogleCalendarImage from "../../icons/GoogleCalendarImage";

interface IGettingStartedTutorialCard {
  title: string;
  link: string;
  image: React.ReactNode;
  description: string;
  tag?: string;
}

const GettingStartedTutorialCard: React.FC<IGettingStartedTutorialCard> = ({
  title,
  link,
  image,
  description,
  tag = "Tutorial",
}) => {
  return (
    <a href={link}>
      <Card
        mah={250}
        shadow="none"
        padding="lg"
        radius="md"
        withBorder
        style={{ cursor: "pointer" }}
      >
        <Card.Section>{image}</Card.Section>

        <Group justify="space-between" mt="md" mb="xs">
          <Text fw={500} c="myColor" size="sm">
            {title}
          </Text>
          {/* <Badge color="myColor" size="xs" radius={"sm"}>
            {tag}
          </Badge> */}
        </Group>

        <Text size="xs" c="dimmed" truncate>
          {description}
        </Text>
      </Card>
    </a>
  );
};
export default GettingStartedTutorialCard;
