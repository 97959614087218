import React, { useEffect, useState } from 'react';
import { ChatMessageBoxUser } from './ChatMessageBox';
import { MessageType } from '../../../handler/message/message';
import { Box, Divider, Flex, Paper, rem, Text, Transition } from '@mantine/core';
import Markdown from 'react-markdown';
import classes from "./textLoading.module.css"
import { IoHammer } from 'react-icons/io5';
interface IChatMessageUpdatingBox {
    visible?: boolean;
    message?: MessageType;
    messageStack: Record<string, any>[];
    finalText?: string;
    isTypeByLetter?: boolean;
    duration?: number;
    //for scrolling
    ref: React.RefObject<HTMLDivElement>
}

const useTypingEffect = (
    text: string,
    duration: number,
    isTypeByLetter = false,

) => {
    const [currentPosition, setCurrentPosition] = useState(0);
    const items = isTypeByLetter ? text.split("") : text.split(" ");

    useEffect(() => {
        setCurrentPosition(0);
    }, [text]);

    useEffect(() => {
        if (currentPosition >= items.length) return;

        const intervalId = setInterval(() => {
            setCurrentPosition((prevPosition: number) => prevPosition + 1);

        }, duration);

        return () => {
            clearInterval(intervalId);
        };
    }, [currentPosition, items, duration]);

    return items.slice(0, currentPosition).join(isTypeByLetter ? "" : " ");
};

const ChatMessageUpdatingBox: React.FC<IChatMessageUpdatingBox> = ({ visible, messageStack }) => {

    // const textToShow = useTypingEffect(
    //     finalText,
    //     duration,
    //     isTypeByLetter,
    //     ref
    // );
    const [message, setMessage] = useState<string>("Thinking...")
    const messagePrefix: Record<string, string> = {
        empty: "Thinking...",
        action: "Using",
        user: "Analysing...",
    }
    const createMessage = () => {
        if (messageStack && messageStack.length > 0) {
            const latest = messageStack[messageStack.length - 1]
            const by = latest.type
            if (by === "action") {
                setMessage(`${messagePrefix[by]} ${latest.title}...`)
            } else if (by === "user") {
                setMessage(`${messagePrefix[by]}`)
            }
        } else if (messageStack.length === 0) {
            setMessage(`${messagePrefix["empty"]}`)
        }
    }
    useEffect(() => {
        createMessage()
    }, [messageStack])
    return (
        <Box display={visible ? "block" : "none"}>
            <Divider my={16} />
            {/* <ChatMessageBoxUser message={message} /> */}

            <Box style={{ width: "100%" }} >
                <Flex direction="column">
                    <div style={{ marginRight: 12, fontSize: rem(14) }}>
                        <Flex>
                            <code className='shimmer' style={{ marginTop: 10 }}>
                                {useTypingEffect(message, 50, true)}
                            </code>
                        </Flex>

                    </div>
                </Flex>

            </Box>
        </Box>
    );
};

export default ChatMessageUpdatingBox;
