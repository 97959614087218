export const ActionDescriptionMap: Record<string, any> = {
    "GOOGLE_CALENDAR_EVENT_CREATE": {
        name: "Create Calendar Event",
        description: "Create Google Calendar events",
        action: "GOOGLE_CALENDAR_EVENT_CREATE"
    },
    "GOOGLE_CALENDAR_EVENT_SEARCH": {
        name: "Search Calendar Events",
        description: "Searches events in your Google Calendar",
        action: "GOOGLE_CALENDAR_EVENT_SEARCH"
    },
    "GOOGLE_CALENDAR_EVENT_UPDATE": {
        name: "Update Calendar Events",
        description: "Updates the details of your Google Calendar",
        action: "GOOGLE_CALENDAR_EVENT_UPDATE"
    },
    "GOOGLE_CALENDAR_EVENT_DELETE": {
        name: "Delete Calendar Events",
        description: "Deletes your Google Calendar event",
        action: "GOOGLE_CALENDAR_EVENT_DELETE"
    },
    "GOOGLE_GMAIL_DRAFT_CREATE": {
        name: "Create Email Draft",
        description: "Creates an email draft based on your description",
        action: "GOOGLE_GMAIL_DRAFT_CREATE"
    },
    "GOOGLE_GMAIL_EMAIL_SEND": {
        name: "Send Email",
        description: "Sends an email directly to your recepient",
        action: "GOOGLE_GMAIL_EMAIL_SEND"
    },
    "GOOGLE_GMAIL_THREAD_SEARCH": {
        name: "Search Email Threads",
        description: "Searches email threads based on your description",
        action: "GOOGLE_GMAIL_THREAD_SEARCH"
    },
    "GOOGLE_GMAIL_THREAD_READ": {
        name: "Read Email Thread",
        description: "Reads specific email thread",
        action: "GOOGLE_GMAIL_THREAD_READ"
    },
    "NOTION_PAGE_GET": {
        name: "Get Page",
        description: "Gets full Notion Page content",
        action: "NOTION_PAGE_GET"
    },
    "NOTION_DATABASE_SEARCH": {
        name: "Search Database",
        description: "Searches Notion Database based on text",
        action: "NOTION_DATABASE_SEARCH"
    },
    "ONWORD_CALCULATOR": {
        name: "Calculate Mathematical Expressions",
        description: "Gives LLMs ability to calculate mathematical expressions",
        action: "ONWORD_CALCULATOR"
    },
    "ONWORD_DATETIME": {
        name: "Date Counter",
        description: "Date and Time counter to help AI with your important dates",
        action: "ONWORD_DATE_COUNTER"
    },
    "ONWORD_DOCUMENT_SEARCH": {
        name: "Search Document",
        description: "Searches document for relevant information",
        action: "ONWORD_DOCUMENT_SEARCH"
    }
}