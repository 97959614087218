import { ActionIcon, Box, FileButton, Flex, Paper, Textarea } from "@mantine/core"
import { useState } from "react"
import { IoAttach, IoSend } from "react-icons/io5"
import FilePillList from "./files/FilePillList"
import { IFilePill } from "./files/FilePill"
import { FileMessageType } from "../../pages/chat/ChatPage"
import { useParams } from "react-router-dom"
import { MessageRunPostType, serialiseMessage } from "../../helper/chat/chatHelper"
import { ConversationType } from "../../handler/conversation/conversation"
import { useForm } from "@mantine/form"
import { getHotkeyHandler, useHotkeys, useLocalStorage } from "@mantine/hooks"
import { createAgent } from "../../handler/agent/agent"
import { createAgentRun, MessageType } from "../../handler/message/message"
import { useAuth0 } from "@auth0/auth0-react"
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query"
import { getAiGeneratedTitle } from "../../handler/ai/aiHandler"
import { uploadFiles } from "../../handler/files/fileHandlers"
import { notifications } from "@mantine/notifications"
import { IconX } from "@tabler/icons-react"



interface IChatBoxProps {
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    conversation: ConversationType
    files: FileMessageType[];
    setFiles: React.Dispatch<React.SetStateAction<FileMessageType[]>>;
    setMessageStack: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;
    setConversation: React.Dispatch<React.SetStateAction<ConversationType | undefined>>
    endRef: React.RefObject<HTMLDivElement>
    refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<ConversationType, Error>>
}

const appendMessageToConversation = (
    conversation: ConversationType,
    setConversation: React.Dispatch<React.SetStateAction<ConversationType | undefined>>,
    newMessage: MessageType) => {
    if (setConversation && conversation) {
        setConversation({
            ...conversation,

            messages: [...conversation.messages, newMessage]
        });
    }
}


const ChatBox: React.FC<IChatBoxProps> = ({ refetch,
    loading,
    setLoading,
    files,
    setFiles,
    conversation,
    setMessageStack,
    setConversation,
    endRef }) => {
    const { id } = useParams<{ id: string }>()
    const [fileButtonLoading, setFileButtonLoading] = useState<boolean>(false)
    const { getAccessTokenSilently } = useAuth0()
    const [_, setValue] = useLocalStorage({
        key: `conversation_title_creation`,
        defaultValue: "",
    });
    const form = useForm(
        {
            initialValues: {
                messageText: ""
            }
        }
    )
    // useHotkeys([
    //     ['Enter', async () => await sendMessage()],
    // ])
    const onDone = async () => {
        if (conversation.messages.length === 0) {
            const token: string = await getAccessTokenSilently()
            await getAiGeneratedTitle(token, conversation._id)
            setValue(conversation._id)
        }
    }
    const sendMessage = async () => {
        // if (conversation && form.values.messageText) {
        const message: MessageRunPostType = serialiseMessage(conversation?.agentId as string, conversation?._id as string, form.values.messageText, files)
        const token: string = await getAccessTokenSilently()
        clearChatBox()
        // set loading
        setLoading(true)
        const newMessage: MessageType = {
            ...message.inputMessage
        }
        // post message
        appendMessageToConversation(conversation, setConversation, newMessage)
        createAgentRun(token, message, refetch, setMessageStack, setLoading, onDone)

    }
    const clearChatBox = () => {
        setFiles([])
        form.values.messageText = ""
    }
    const onFileAdd = async (files: File[]) => {
        try {
            const token = await getAccessTokenSilently()
            setFileButtonLoading(true)
            let uploadedFiles = await uploadFiles(token, files)
            for (let file of uploadedFiles) {
                if (["image/png", "image/jpeg", "image/jpg"].includes(file.contentType)) {
                    const fileFormat = file.contentType
                    file.contentType = "image"
                    file["fileType"] = fileFormat

                } else {
                    file.contentType = "document"
                }
            }
            setFileButtonLoading(false)
            setFiles(prevFiles => [...prevFiles, ...uploadedFiles] as FileMessageType[])
        }
        catch (error) {
            setFileButtonLoading(false)
            notifications.show({
                title: 'Upload Error',
                message: `Failed to upload files. Please try again.`,
                color: 'red',
                icon: <IconX />
            });
        }
    }
    return (
        <Flex w={"100%"} maw={700} direction={"column"} gap={8}>
            <FilePillList files={files} setFiles={setFiles} />
            <Paper shadow="md">
                <Textarea
                    autoFocus
                    disabled={loading}
                    size="md"
                    placeholder="Message..."
                    styles={{ input: { borderColor: "rgb(223,226,230)" } }}
                    autosize
                    minRows={1}
                    maxRows={4}
                    w={"100%"}
                    leftSection={
                        // TODO: Change this to have file handler 
                        <FileButton onChange={onFileAdd} multiple>
                            {(props) => <ActionIcon
                                loading={fileButtonLoading}
                                {...props}
                                color="myColor"
                                variant={files.length > 0 ? "light" : "subtle"}
                                disabled={loading}>
                                <IoAttach />
                            </ActionIcon>}
                        </FileButton>
                    }
                    rightSection={
                        <ActionIcon
                            color="myColor"
                            disabled={loading}
                            loading={loading}
                            onClick={sendMessage}
                        >
                            <IoSend />
                        </ActionIcon>
                    }
                    key={form.key("messageText")}
                    {...form.getInputProps("messageText")}
                    onKeyDown={getHotkeyHandler([
                        ['Enter', sendMessage],
                    ])}
                />
            </Paper>
        </Flex>
    )
}
export default ChatBox