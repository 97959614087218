import axios from "axios";
import { MONOLITH_ROUTE } from "../../routes";

export const uploadFile = async (token: string, fileBytes: ArrayBuffer, filename: string, contentType: string) => {
    try {
        const formData = new FormData();
        const blob = new Blob([fileBytes], { type: contentType });
        formData.append('file', blob, filename);
        const response = await axios.post(

            `${MONOLITH_ROUTE}/rag/upload`, // Adjust the URL as necessary
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        return response.data;
    } catch (error) {
        throw new Error(`Error uploading file: ${error}`);
    }
}

