import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Outlet,
  Navigate,
  redirect,
  useParams,
  useNavigate,
  Link,
} from "react-router-dom";
import {
  ActionIcon,
  Image,
  AppShell,
  Avatar,
  Badge,
  Burger,
  Button,
  Center,
  Divider,
  em,
  Flex,
  Group,
  Input,
  Kbd,
  NavLink,
  Paper,
  ScrollArea,
  Skeleton,
  Space,
  Stack,
  Text,
  Pill,
  Affix,
  Tooltip,
} from "@mantine/core";
import { spotlight } from "@mantine/spotlight";
import {
  useDisclosure,
  useHotkeys,
  useLocalStorage,
  useMediaQuery,
} from "@mantine/hooks";
import {
  IoSearch,
  IoDesktop,
  IoCube,
  IoLink,
  IoAdd,
  IoChatboxEllipses,
} from "react-icons/io5";
import { RiRobot2Fill } from "react-icons/ri";
import UserFrameDropdown from "../components/frame/UserFrameDropdown";
import {
  ConversationViewType,
  getConversations,
} from "../handler/conversation/conversation";
import { useQuery } from "@tanstack/react-query";
import FrameConversationCard from "../components/conversation/FrameConversationCard";
import NavigationSpotlight from "../components/spotlight/NavigationSpotlight";
import CreateChatModal from "../components/chat/modal/CreateChatModal";
import { getAllAgents } from "../handler/agent/agent";
import { AgentType } from "../types/agent/agent";
import OnwordLogo from "../assets/logo.svg";
import { notifications } from "@mantine/notifications";
import { IconInfoCircle, IconX } from "@tabler/icons-react";

interface IRoute {
  path: string;
  title: string;
  description?: string;
  icon: React.ReactNode;
}

const routes: IRoute[] = [
  {
    path: "/",
    title: "Agents",
    icon: <RiRobot2Fill />,
  },
  {
    path: "/integrations",
    title: "Integrations",
    icon: <IoLink />,
  },
];

const ProtectedRoute = () => {
  const { isAuthenticated, isLoading, user, getAccessTokenSilently } =
    useAuth0();
  const [
    createChatModalOpened,
    { open: createChatModalOpen, close: createChatModalClose },
  ] = useDisclosure(false);
  const [conversations, setConversations] = useState<ConversationViewType[]>(
    []
  );
  const [agents, setAgents] = useState<AgentType[]>([]);
  const [agentLoading, setAgentLoading] = useState<boolean>(false);
  const [opened, { toggle }] = useDisclosure(true);
  const [value, _] = useLocalStorage({
    key: `conversation_title_creation`,
  });
  const navigate = useNavigate();
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        navigate("/login");
      }
    }
  }, [isAuthenticated, isLoading]);
  const getConvos = async () => {
    try {
      if(isAuthenticated){
      const token = await getAccessTokenSilently();
      let response = await getConversations(token);
      response = response.sort(
        (a, b) =>
          new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
      );
      setConversations(response);}
    }
    catch (error) {
      setAgentLoading(false)
      notifications.show({
        title: "Error",
        message: "Failed to fetch conversations. Please try again later.",
        icon: <IconX />,
        color: "red",
      });
    }
  };
  useEffect(() => {
    getConvos();
  }, [value, isAuthenticated]);
  useEffect(() => {
    const getAgents = async () => {
      try {
        if(isAuthenticated){
          setAgentLoading(true);
        const token = await getAccessTokenSilently();
        const response = await getAllAgents(token);
        setAgents(response);
        setAgentLoading(false);}
      }
      catch (error) {
        notifications.show({
          title: "Error",
          message: "Failed to fetch agents. Please try again later.",
          icon: <IconX />,
          color: "red",
        });
      }
    };
    getAgents();
  }, [isAuthenticated]);
  useHotkeys([["shift+C", () => createChatModalOpen()]]);
  return (
    <>
      <AppShell
        header={{ height: 56 }}
        navbar={{
          width: 230,
          breakpoint: "sm",
          collapsed: { mobile: !opened, desktop: !opened },
        }}
        padding="md"
      >
        <AppShell.Header style={{ display: "flex" }}>
          <Flex style={{ width: "100%", marginBlock: "auto" }} px={12} gap={12}>
            <Burger
              opened={opened}
              onClick={toggle}
              size={"sm"}
              color="gray"
              mt={4}
            />
            <Badge radius={"sm"} color="myColor" style={{ margin: "auto" }}>
              BETA
            </Badge>
            {/* <Image src={OnwordLogo} height={24} style={{ margin: "auto" }} /> */}
            <div style={{ flexGrow: 1 }} />
            <Paper
              w={160}
              p={6}
              withBorder
              onClick={spotlight.open}
              visibleFrom="sm"
              radius={"md"}
              color="myColor"
              style={{
                display: "flex",
              }}
            // leftSection={<IoSearch />}
            >
              <Text c={"dimmed"} size="sm" mt={2} ml={2}>
                Search
              </Text>
              <div style={{ flexGrow: 1 }} />
              <Kbd size="xs">/</Kbd>
            </Paper>
            <UserFrameDropdown>
              <Avatar src={user?.picture} />
            </UserFrameDropdown>
          </Flex>
        </AppShell.Header>
        <AppShell.Navbar p="md">
          {routes.map((route, index) => (
            <Link to={route.path}>
              <NavLink
                active={window.location.pathname === route.path}
                color="myColor"
                label={route.title}
                // description={route.description}
                leftSection={route.icon}
                py={12}
                variant="light"
              />
            </Link>
          ))}
          <Divider my={14} />
          <Text size="sm" c="dimmed" ml={6}>
            Chats
          </Text>
          <ScrollArea h={"100%"} mt={14}>
            <Stack gap={10} style={{ width: isMobile ? "100%" : 170 }}>
              {conversations.length > 0 ? (
                <>
                  {conversations.map((conversation, index) => {
                    return (
                      <>
                        <FrameConversationCard
                          trigger={getConvos}
                          isTyping={conversation._id === value}
                          conversation={conversation}
                        />
                        {index !== conversations.length - 1 ? (
                          <Divider />
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}
                </>
              ) : (
                <div
                  style={{
                    marginTop: 16,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <IoChatboxEllipses
                    color="lightgray"
                    style={{ width: 24, height: 24 }}
                  />
                  <Text size="xs" c="dimmed">
                    Click 'New Chat' to Start
                  </Text>
                </div>
              )}
            </Stack>
          </ScrollArea>
          <Divider my={14} />
          <Button
            loading={agentLoading}
            // leftSection={<IoAdd />}
            mih={36}
            color="myColor"
            onClick={createChatModalOpen}
          >
            New Chat (⇧ + C)
          </Button>
        </AppShell.Navbar>
        <AppShell.Main>
          <Outlet />
        </AppShell.Main>
      </AppShell>
      <NavigationSpotlight agents={agents} />

      <CreateChatModal
        agents={agents !== undefined ? agents : []}
        opened={createChatModalOpened}
        close={createChatModalClose}
      />
      <Affix position={{ bottom: 20, right: 20 }}>
        <Tooltip label="Feedback"
        >

          <ActionIcon color="myColor" variant="subtle"
            onClick={() => {
              window.location.href = "https://forms.gle/s8kgKqnEBAD4nmbS7"
            }}
          ><IconInfoCircle /></ActionIcon>

        </Tooltip>
      </Affix>
    </>
  );
};
export default ProtectedRoute;
