import React from "react";
import OutlookEmail from "../../assets/microsoft_outlook_email.png";
import { Image } from "@mantine/core";
import { IAddApplicationCard } from "./GoogleCalendarImage";


const OutlookEmailImage: React.FC<IAddApplicationCard> = ({ width = 28, height = 28, style }) => {
    return <Image src={OutlookEmail} w={width} h={height} style={style} />;
}

export default OutlookEmailImage;
