import axios from "axios";
import { MONOLITH_ROUTE } from "../routes";

export interface RunAction {
  type: "user" | "agent" | "action";
  title: string;
  text: string;
  args: Record<string, any> | null;
  duration: any | null;
  startedAt: string;
}

export interface Run {
  _id: string; // Assuming ObjectId is represented as a string
  agentId: string;
  createdBy: string;
  createdOn: string;
  status: "RUN_NOT_STARTED" | "RUN_ERROR" | "RUN_IN_PROGRESS" | "RUN_COMPLETED";
  actions: RunAction[];
}

export const getRun = async (token: string, runId: string): Promise<Run> => {
  try {
    const response = await axios.get(`${MONOLITH_ROUTE}/runs/${runId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data as Run;
  } catch (error) {
    throw new Error("There was an error getting the agent run");
  }
};
