import { Stack, Textarea, TextInput } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

interface INameDescriptionInput {
    textLabel: string
    textFormKey: string
    textPlaceholder: string
    textAreaFormKey: string
    textAreaLabel: string
    textAreaPlaceholder: string
    form: UseFormReturnType<any, any>
}

const NameDescriptionInput: React.FC<INameDescriptionInput> = ({
    form,
    textLabel,
    textAreaLabel,
    textAreaPlaceholder,
    textAreaFormKey,
    textFormKey,
    textPlaceholder }) => {
    return (<Stack gap={4} mt={8}>
        <TextInput
            c={"dimmed"}
            label={textLabel}
            withAsterisk placeholder={textPlaceholder}
            key={form.key(textFormKey)}
            {...form.getInputProps(textFormKey)}
        />
        <Textarea
            c={"dimmed"}
            withAsterisk
            label={textAreaLabel}
            placeholder={textAreaPlaceholder}
            key={form.key(textAreaFormKey)}
            {...form.getInputProps(textAreaFormKey)}
        />
    </Stack>)
}
export default NameDescriptionInput