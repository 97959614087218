

export const validateActionSet = (incoming: string[], accepted: string[]): void => {
    for (let action of incoming) {
        if (!accepted.includes(action)) throw new Error(`${action} is not a valid action`)
    }
}

export const validateStringEmpty = (fieldName: string, fieldValue: string) => {
    if (fieldValue.length === 0 || fieldValue === undefined || fieldValue.replaceAll(" ", "").length === 0)
        throw new Error(`${fieldName} cannot be empty`)
}

export const isInstanceOfType = <T>(obj: any, type: new (...args: any[]) => T): void => {
    if (!(obj instanceof type)) {
        throw new Error(`Object is not of type ${type.name}`);
    }
}

export const validateStringHasSpace = (fieldName: string, fieldValue: string) => {
    if (fieldValue.includes(" ")) {
        throw new Error(`${fieldName} cannot contain spaces`);
    }
}
