import { Avatar, Box, Button, Flex, Grid, Group, Paper, Pill, ScrollArea, Skeleton, Stack, Text, Textarea, TextInput } from "@mantine/core";
import GoogleCalendarImage from "../../../../../../components/icons/GoogleCalendarImage";
import { useEffect, useState } from "react";
import { IoAdd, IoCalendar, IoPersonAdd } from "react-icons/io5";
import { useForm } from "@mantine/form"
import { GoogleCalendarActionType, GoogleCalendarActions, GoogleCalendarDataType } from "../../../../../../types/integration/action/GoogleCalendar/googleCalendar";
import { PermissionType } from "../../../../../../types/permission/permission";
import { useAuth0 } from "@auth0/auth0-react";
import { getGoogleCalendarList } from "../../../../../../handler/apps/google/calendar/googleCalendarHandler";
import { CreateIntegrationType, IntegrationType } from "../../../../../../types/integration/integration";
import { createIntegration } from "../../../../../../handler/integration/integration";
import { useNavigate } from "react-router-dom";
import IntegrationHeader from "../../../../../../components/integration/header/IntegrationPermissionsList";
import AddAccountHeader from "../../../../../../components/integration/header/google/AddAccountHeader";
import NoPermissionsDisplay from "../../../../../../components/permission/display/NoPermissionsDisplay";
import GenericIntegrationTitle from "../../../../../../components/title/GenericIntegrationTitle";
import ActionCheckCard from "../../../../../../components/integration/action/ActionCheckCard";
import GenericRadioCard from "../../../../../../components/integration/card/GenericRadioCard";
import { formatActions } from "../../../../../../helper/integration/tool";
import { ApplicationActionType } from "../../../../../../types/integration/action/action";
import NameDescriptionInput from "../../../../../../components/integration/input/NameDescriptionInput";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconCross, IconX } from "@tabler/icons-react";



interface IGoogleCalendarIntegrationForm {
    permissions: PermissionType[]
    actions: GoogleCalendarActionType[]
}
type GoogleCalendarIntegrationSubmissionBody = {
    name: string
    description: string
    permission: PermissionType | undefined;
    calendar: GoogleCalendarDataType | undefined;
    actions: GoogleCalendarActionType[];

}

const GoogleCalendarIntegrationForm: React.FC<IGoogleCalendarIntegrationForm> = ({ permissions, actions }) => {
    const { getAccessTokenSilently } = useAuth0()
    const [calendars, setCalendars] = useState<GoogleCalendarDataType[]>()
    const [calendar, setCalendar] = useState<GoogleCalendarDataType>()
    const [calendarsLoading, setCalendarLoading] = useState<boolean>(false)
    const [createIntegrationLoading, setCreateIntegrationLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const [permission, setPermission] = useState<PermissionType>()
    const [listedActions, setListedActions] = useState(actions)
    const form = useForm({
        initialValues: {
            name: "",
            description: "",
            permission: permissions ? permissions[0] : undefined,
            calendar: calendars ? calendars[0] : undefined,
            actions: [...listedActions]
        }
    })

    const onCheckActions = (changedAction: GoogleCalendarActionType): void => {
        const actionNames = form.values.actions.map((action) => action.action)
        if (actionNames.includes(changedAction.action)) {
            form.setFieldValue("actions", form.values.actions.filter((action: GoogleCalendarActionType) => action.action !== changedAction.action))
        } else {
            form.setFieldValue("actions", [...form.values.actions, changedAction])
        }
    }

    useEffect(() => {
        const getCalendars = async () => {
            if (permission) {
                form.setFieldValue("calendar", undefined)
                setCalendars(undefined)
                setCalendarLoading(true)
                const token = await getAccessTokenSilently()
                const calendarsResponse = await getGoogleCalendarList(token, permission._id)
                setCalendarLoading(false)
                setCalendars(calendarsResponse)
            }
        }
        getCalendars()
    }, [permission])
    useEffect(() => {
        if (calendar) {

            const abilitiesMap: Record<string, string[]> = {
                owner: ["GOOGLE_CALENDAR_EVENT_CREATE", "GOOGLE_CALENDAR_EVENT_SEARCH", "GOOGLE_CALENDAR_EVENT_UPDATE", "GOOGLE_CALENDAR_EVENT_DELETE"],
                reader: ["GOOGLE_CALENDAR_EVENT_SEARCH"],
                writer: ["GOOGLE_CALENDAR_EVENT_CREATE", "GOOGLE_CALENDAR_EVENT_SEARCH", "GOOGLE_CALENDAR_EVENT_UPDATE", "GOOGLE_CALENDAR_EVENT_DELETE"],
                freeBusyReader: ["GOOGLE_CALENDAR_EVENT_SEARCH"]
            }
            const allowedActions = actions.filter((item) => abilitiesMap[calendar.accessRole].includes(item.action))
            setListedActions(allowedActions)
            form.setFieldValue("actions", allowedActions)
        }
    }, [calendar])
    const submitIntegration = async (values: GoogleCalendarIntegrationSubmissionBody): Promise<IntegrationType> => {
        // 1) validate values
        // 2) format into create integration type
        try {
            setCreateIntegrationLoading(true)
            const formattedTools: ApplicationActionType[] = formatActions(form.values.name, form.values.description, form.values.actions)
            const integration: CreateIntegrationType = {
                name: form.values.name,
                description: form.values.description,
                permission_id: values.permission?._id as string,
                config: { ...(calendar as GoogleCalendarDataType), "preferred_timezone": calendar?.timeZone },
                tools: formattedTools
            }
            const token = await getAccessTokenSilently()
            const response = await createIntegration(token, integration)
            setCreateIntegrationLoading(false)
            notifications.show({
                title: <Text c="myColor">{`Successfully created ${integration.name}`}</Text>,
                message: `Your ${integration.name} has been created and is ready for use`,
                autoClose: 3000,
                color: "teal",
                icon: <IconCheck />
            })
            return response as IntegrationType

        } catch (e) {
            setCreateIntegrationLoading(false)
            notifications.show({
                title: <Text c="myColor">{`Failed to create ${form.values.name}`}</Text>,
                message: `Your ${form.values.name} has not been integrated`,
                autoClose: 3000,
                color: "red",
                icon: <IconX />
            })
            return {} as IntegrationType
        }
    }
    const submitIntegrationRedirectToAgent = async (values: GoogleCalendarIntegrationSubmissionBody) => {
        const response = await submitIntegration(values)
        navigate("/agents/create", { state: response })
    }
    return (
        <>
            <IntegrationHeader
                icon={<GoogleCalendarImage />}
                title={"Google Calendar"}
            />
            <br></br>
            <form onSubmit={form.onSubmit(submitIntegration)}>
                <Stack gap={25}>
                    <div>
                        <GenericIntegrationTitle
                            title={"1. Name & Describe Integration"}
                            subtext={"Name & describe integration for your agents"}
                        />
                        <NameDescriptionInput
                            form={form}
                            textFormKey="name"
                            textAreaFormKey="description"
                            textLabel="Name"
                            textPlaceholder="Example: Work Calendar"
                            textAreaLabel="Description"
                            textAreaPlaceholder="Example: For managing work related events"
                        />
                    </div>
                    <div>
                        <AddAccountHeader
                            oauthRoute={"/google_calendar"}
                            title={"2. Add Your Account"}
                            subtext={"Add your Google Calendar account to connect with your calendar"}
                        />

                        {permissions === undefined ? (
                            <NoPermissionsDisplay
                                text={"It seems you haven't created linked an account for this app yet, click the 'Add Account' above to start'"}
                                icon={<IoPersonAdd color="lightgray" style={{ width: 56, height: 56 }} />}
                            />
                        ) : (

                            <Stack my={10} gap={4}>
                                {permissions?.map((permission: any) => {
                                    return (
                                        <GenericRadioCard
                                            avatar={<Avatar src={permission.picture} size={"sm"} style={{ margin: "auto" }} />}
                                            title={<Text size="sm">{permission.userName}</Text>}
                                            subtext={permission.email}
                                            checked={form.values.permission?._id === permission._id}
                                            onChecked={() => {
                                                form.setFieldValue("permission", permission)
                                                setPermission(permission)
                                            }}
                                        />)
                                })}

                            </Stack>)}
                    </div>

                    <Box display={form.values.permission == undefined ? "none" : "block"}>
                        <GenericIntegrationTitle
                            title="3. Choose Calendar"
                            subtext="Choose the calendar you want to connect to"
                        />
                        {calendars === undefined ? (<Paper withBorder my={10} mih={100} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Skeleton visible={calendarsLoading}>
                                <Flex w={400} direction={"column"} align={"center"} gap={10} p={20} >
                                    <IoCalendar color="lightgray" style={{ width: 56, height: 56, }} />
                                    <Text size="xs" c="dimmed" style={{ textAlign: "center" }}>It seems you haven't created a calendar in Google Calendar yet</Text>
                                </Flex>
                            </Skeleton>
                        </Paper>) : (
                            <ScrollArea h={150} my={10} >
                                <Stack gap={4}>
                                    {calendars.map((calendar: GoogleCalendarDataType) => {
                                        return (
                                            <GenericRadioCard
                                                avatar={<IoCalendar color={calendar.backgroundColor} style={{ width: 24, height: 24, margin: "auto" }} />}
                                                title={<Text size="sm">{calendar.summary} <Pill>{calendar.accessRole}</Pill></Text>}
                                                subtext={calendar.descriptionl}
                                                checked={form.values.calendar?.id === calendar.id}
                                                onChecked={() => {
                                                    form.setFieldValue("calendar", calendar)
                                                    setCalendar(calendar)
                                                }
                                                }
                                            />
                                        );
                                    })}
                                </Stack>
                            </ScrollArea>
                        )}
                    </Box>
                    <Box display={form.values.calendar === undefined ? "none" : "block"}>
                        <GenericIntegrationTitle
                            title="4. Confirm Actions"
                            subtext="Actions are generated from integrations, you can choose which actions you want your agent to use"
                        />
                        <Grid gutter={4} my={10}>
                            {
                                listedActions.map((action) => {
                                    return (
                                        <Grid.Col span={6}>
                                            <ActionCheckCard
                                                name={action.name}
                                                description={action.description}
                                                checked={form.values.actions.map(action => action.action).includes(action.action)}
                                                onChecked={() => onCheckActions(action)}
                                            />
                                        </Grid.Col>
                                    )
                                })
                            }
                        </Grid>

                    </Box>
                    <Flex mt={10} display={form.values.calendar === undefined ? "none" : "block"}>
                        <div style={{ flexGrow: 1 }} />
                        <Group >
                            <Button
                                loading={createIntegrationLoading}
                                variant="light"
                                color="myColor"
                                onClick={async () => {
                                    await submitIntegration(form.values)
                                    navigate("/integrations")
                                }}>
                                Create Integration
                            </Button>
                            <Button
                                leftSection={<IoAdd />}
                                color="myColor"
                                onClick={async () => await submitIntegrationRedirectToAgent(form.values)}
                                loading={createIntegrationLoading}
                            >
                                Create Agent w/ Integration
                            </Button>
                        </Group>
                    </Flex>
                </Stack>
            </form >
        </>
    )
}

export default GoogleCalendarIntegrationForm;