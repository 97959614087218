import {
  ActionIcon,
  Card,
  Flex,
  Grid,
  Paper,
  rem,
  Text,
  Image,
  Button,
} from "@mantine/core";

import GettingStartedTutorialCard from "./card/GettingStartedTutorialCard";
import GettingStartedPhoto1 from "../../assets/gettingstarted1.jpeg";
import GettingStartedPhoto2 from "../../assets/gettingstarted2.jpeg";
import GettingStartedPhoto3 from "../../assets/gettingstarted3.png";
import GettingStartedPhoto4 from "../../assets/gettingstarted4.png";
import { IoBook } from "react-icons/io5";
const GettingStartedPanel = () => {
  return (
    <Paper
      withBorder
      p={25}
      radius={"lg"}
      style={{
        backgroundImage:
          "linear-gradient(-15deg,#5961ff 0%, #ffffff 50%, #ffffff 100%)",
      }}
    >
      {" "}
      <Flex direction={"row"}>
        <Flex style={{ flexGrow: 1 }} direction={"column"}>
          <p
            style={{
              fontWeight: 600,
              fontSize: rem(24),
              margin: 0,
            }}
            className="shimmer"
          >
            Getting Started ✨
          </p>
          <Text c="dimmed" size="xs">
            Check out tutorials to see how Onword can supercharge your
            productivity
          </Text>
        </Flex>

        <Button
          rightSection={<IoBook />}
          color="myColor"
          variant="light"
          onClick={() => window.open("https://onword.ai/blog", "_blank")}
        >
          See Blog
        </Button>

        {/* <ActionIcon variant="subtle" color="myColor">
          <IconX />
        </ActionIcon> */}
      </Flex>
      <Grid mt={16}>
        <Grid.Col span={3}>
          <GettingStartedTutorialCard
            title="Getting Started With Onword AI"
            description="Dive into all of Onword's AI-powered features"
            link="https://onword.ai/blog/getting-started"
            image={<Image src={GettingStartedPhoto1} height={130} />}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <GettingStartedTutorialCard
            title="Create Your Own Personal Tutor"
            description="Study & ace your tests with the help of powerful agents"
            link="https://onword.ai/blog/create-your-own-personal-tutor"
            image={<Image src={GettingStartedPhoto2} height={130} />}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <GettingStartedTutorialCard
            title="Sales Lead Email Generation"
            description="Automate mundane email generation with Onword"
            link="https://onword.ai/blog/automate-sales-lead-email-generate-with-onword"
            image={<Image src={GettingStartedPhoto3} height={130} />}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <GettingStartedTutorialCard
            title="Connect Calendar With Email"
            description="Automate email drafting & event calendar creation"
            link="https://onword.ai/blog/connect-google-calendar-with-gmail"
            image={<Image src={GettingStartedPhoto4} height={130} />}
          />
        </Grid.Col>
      </Grid>
    </Paper>
  );
};

export default GettingStartedPanel;
