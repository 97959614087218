import { PermissionType } from "../../types/permission/permission";
import axios from "axios"
import { MONOLITH_ROUTE } from "../routes";

export const getAppPermissions = async (appName: string, token: string): Promise<PermissionType[]> => {
    try {

        const response = await axios.get(`${MONOLITH_ROUTE}/permissions`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                appUniqueName: appName
            }
        })
        return response.data as PermissionType[]
    }
    catch (e) {
        throw new Error("There was an error fetching the app permissions")
    }
}


