import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import "@mantine/code-highlight/styles.css";
import "@mantine/notifications/styles.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  createTheme,
  MantineProvider,
  MantineColorsTuple,
  Button,
  Grid,
  Paper,
  Flex,
} from "@mantine/core";
import ProtectedRoute from "./routes/ProtectedRoute";
import ChatPage from "./pages/chat/ChatPage";
import IntegrationPage from "./pages/integration/IntegrationPage";
import GoogleGmailIntegrationPage from "./pages/integration/base/apps/google/GoogleGmail/GoogleGmailIntegrationPage";
import GoogleCalendarIntegrationPage from "./pages/integration/base/apps/google/GoogleCalendar/GoogleCalendarIntegrationPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NotionIntegrationPage from "./pages/integration/base/apps/notion/NotionIntegrationPage";
import { Notifications } from "@mantine/notifications";
import OnwordDocsIntegrationPage from "./pages/integration/base/apps/onword/OnwordDocsIntegrationPage";
import CreateAgentPage from "./pages/agent/CreateAgentPage";
import OnwordDefaultToolsPage from "./pages/integration/base/apps/onword/OnwordDefaultToolsPage";
import AgentsPage from "./pages/agent/AgentsPage";
import NavigationSpotlight from "./components/spotlight/NavigationSpotlight";
import "@mantine/spotlight/styles.css";
import EditAgentPage from "./pages/agent/EditAgentPage";
import IntegrationViewPage from "./pages/integration/IntegrationViewPage";
import StartUpChatPage from "./pages/chat/StartUpChatPage";
import { IconFile, IconPencil } from "@tabler/icons-react";
import { Text } from "@mantine/core";
import GoogleGmailImage from "./components/icons/GoogleGmailImage";
import GoogleCalendarImage from "./components/icons/GoogleCalendarImage";
import NotionImage from "./components/icons/NotionImage";
import AddAccountIntegrationRedirectPage from "./pages/redirect/AddAccountIntegrationRedirectPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const myColor: MantineColorsTuple = [
  "#ebefff",
  "#d5dafc",
  "#a9b1f1",
  "#7b87e9",
  "#5362e1",
  "#3a4bdd",
  "#2d3fdc",
  "#1f32c4",
  "#182cb0",
  "#0b259c",
];

const theme = createTheme({
  /** Put your mantine theme override here */
  colors: {
    myColor,
  },
});
const queryClient = new QueryClient();
const router = createBrowserRouter([

  {
    path: "/login",
    element: <App />,
  },
  {
    path: "/integration-redirect",
    element: <AddAccountIntegrationRedirectPage />
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: "/dashboard",
        element: <>home</>,
      },
      {
        path: "/",
        element: <AgentsPage />,
      },
      {
        path: "/agents/create",
        element: <CreateAgentPage />,
      },
      {
        path: "/agents/:id",
        element: <EditAgentPage />,
      },
      {
        path: "/integrations",
        element: <IntegrationPage />,
      },
      {
        path: "/integrations/:id",
        element: <IntegrationViewPage />,
      },
      {
        path: "/integrations/google_gmail",
        element: <GoogleGmailIntegrationPage />,
      },
      {
        path: "/integrations/google_calendar",
        element: <GoogleCalendarIntegrationPage />,
      },
      {
        path: "/integrations/notion",
        element: <NotionIntegrationPage />,
      },
      {
        path: "/integrations/docs",
        element: <OnwordDocsIntegrationPage />,
      },
      {
        path: "/integrations/default",
        element: <OnwordDefaultToolsPage />,
      },
      {
        path: "/chats/:id",
        element: <ChatPage />,
      },
      {
        path: "/chats",
        element: <></>,
      }
    ],
  },
]);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-eym6ylpoplxr2f0n.jp.auth0.com"
      clientId="iQAj9MEvHQxCizLW5bHGvLpgNGMwGopZ"
      authorizationParams={{
        audience: "https://dev-eym6ylpoplxr2f0n.jp.auth0.com/api/v2/",
        redirect_uri: window.location.origin,
      }}
    >
      <MantineProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
          <Notifications />
        </QueryClientProvider>
      </MantineProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
