import { rem, Button, useMantineTheme, ColorSwatch } from '@mantine/core';
import { Spotlight, SpotlightActionData, SpotlightActionGroupData, spotlight } from '@mantine/spotlight';
import { IconHome, IconDashboard, IconFileText, IconSearch, IconCheck, IconX, IconFile } from '@tabler/icons-react';
import classes from "./spotlight.module.css"
import { AgentType } from '../../types/agent/agent';
import { IntegrationType } from '../../types/integration/integration';
import { useNavigate } from 'react-router-dom';
import { IoAdd, IoAddCircle, IoChatbox, IoDocument } from 'react-icons/io5';
import { useAuth0 } from '@auth0/auth0-react';
import { ConversationType, createConversation } from '../../handler/conversation/conversation';
import { notifications } from '@mantine/notifications';
import GoogleCalendarImage from '../icons/GoogleCalendarImage';
import GoogleGmailImage from '../icons/GoogleGmailImage';
import NotionImage from '../icons/NotionImage';


interface INavigationSpotlight {
    agents: AgentType[]

}
const NavigationSpotlight: React.FC<INavigationSpotlight> = ({ agents }) => {
    const navigate = useNavigate()
    const { getAccessTokenSilently } = useAuth0()
    const createConversationWithAgent = async (agent: AgentType) => {
        try {
            const token = await getAccessTokenSilently();
            const conversation: ConversationType = await createConversation(
                token,
                `New Chat w/ ${agent.name}`,
                agent._id
            );
            navigate(`/chats/${conversation._id}`);
            notifications.show({
                color: "teal",
                icon: <IconCheck />,
                title: "Successfully Created Chat",
                message: `Successfully started a chat with ${agent.name}.`,
            });
        } catch (error) {
            notifications.show({
                color: "red",
                icon: <IconX />,
                title: "Error",
                message: error instanceof Error ? error.message : "An unknown error occurred",
            });
        }
    }
    const initAgents = (): SpotlightActionGroupData => {
        const agentSpotlightData = agents.map((agent) => {
            return {
                id: `agent-${agent._id}`,
                label: `Chat with ${agent.name}`,
                description: `Start a chat with ${agent.name}`,
                leftSection: <ColorSwatch color={agent.metadata.agentColor} />,
                rightSection: <IoChatbox />,
                onClick: async () => await createConversationWithAgent(agent)
            }
        })
        return {
            group: "Agents", actions: [
                {
                    id: "create-agent",
                    label: `Create New Agent`,
                    description: "Initiate the creation of a new agent",
                    leftSection: <IoAddCircle style={{ width: 28, height: 28 }} />,
                    onClick: () => navigate(`/agents/create`)
                },
                ...agentSpotlightData]
        }
    }
    // const initIntegration = (): SpotlightActionData[] => {
    //     return []
    // }
    const actions: (SpotlightActionData | SpotlightActionGroupData)[] = [
        initAgents(),
        {
            group: "Integrations",
            actions: [
                {
                    id: "google-calendar",
                    label: `Create Google Calendar Integration`,
                    leftSection: <GoogleCalendarImage />,
                    onClick: () => navigate(`/integrations/google_calendar`)
                },
                {
                    id: "google-gmail",
                    label: `Create Google Gmail Integration`,
                    leftSection: <GoogleGmailImage />,
                    onClick: () => navigate(`/integrations/google_gmail`)
                },
                {
                    id: "notion",
                    label: `Create Notion Integration`,
                    leftSection: <NotionImage />,
                    onClick: () => navigate(`/integrations/notion`)
                },

                {
                    id: "documents",
                    label: `Create Document Integration`,
                    leftSection: <IoDocument style={{ color: "lightgray", width: 28, height: 28 }} />,
                    onClick: () => navigate(`/integrations/docs`)
                }

            ]

        }
    ]
    return (
        <>
            <Spotlight

                classNames={classes}
                limit={4}
                shortcut={"/"}
                actions={actions}
                nothingFound="Nothing found..."
                highlightQuery
                searchProps={{
                    leftSection: <IconSearch style={{ width: rem(20), height: rem(20) }} stroke={1.5} />,
                    placeholder: 'Search...',
                }}
            >
            </Spotlight>
        </>
    );
}

export default NavigationSpotlight