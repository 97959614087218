import { ApplicationActionType } from "../../types/integration/action/action";

export const formatActions = (
    integrationName: string,
    integrationDescription: string,
    actions: ApplicationActionType[]): ApplicationActionType[] => {
    let formattedActions: ApplicationActionType[] = []
    for (let action of actions) {
        const formattedAction: ApplicationActionType = {
            name: removeSpaceSymbolsFromString(`${action.name} for ${integrationName}`),
            description: `(name: ${integrationName}\ndescription: ${integrationDescription}) ${action.description}`,
            action: action.action
        }
        formattedActions.push(formattedAction)
    }
    return formattedActions
}

export const removeSpaceSymbolsFromString = (input: string): string => {
    return input
        .replace(/[\s\W]+/g, '') // Remove spaces and non-word characters
        .replace(/^(.)|(\s+.)/g, (match) => match.toUpperCase()); // Convert to PascalCase
}