import { useForm } from "@mantine/form"
import GoogleGmailImage from "../../../../../../components/icons/GoogleGmailImage"
import AddAccountHeader from "../../../../../../components/integration/header/google/AddAccountHeader"
import IntegrationHeader from "../../../../../../components/integration/header/IntegrationPermissionsList"
import NameDescriptionInput from "../../../../../../components/integration/input/NameDescriptionInput"
import { Avatar, Box, Button, Flex, Grid, Group, Stack, Text } from "@mantine/core"
import GenericIntegrationTitle from "../../../../../../components/title/GenericIntegrationTitle"
import { PermissionType } from "../../../../../../types/permission/permission"
import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { getAppPermissions } from "../../../../../../handler/permission/permissionHandler"
import NoPermissionsDisplay from "../../../../../../components/permission/display/NoPermissionsDisplay"
import { IoPersonAdd } from "react-icons/io5"
import GenericRadioCard from "../../../../../../components/integration/card/GenericRadioCard"
import ActionCheckCard from "../../../../../../components/integration/action/ActionCheckCard"
import { ApplicationActionType } from "../../../../../../types/integration/action/action"
import { notifications } from "@mantine/notifications"
import { IconCheck, IconCross } from "@tabler/icons-react"
import { CreateIntegrationType, IntegrationType } from "../../../../../../types/integration/integration"
import { formatActions } from "../../../../../../helper/integration/tool"
import { createIntegration } from "../../../../../../handler/integration/integration"
import { useNavigate } from "react-router-dom"


interface IGoogleGmailIntegrationForm {
    actions: ApplicationActionType[]
}
interface GoogleGmailIntegrationSubmissionBody {
    name: string
    description: string
    permission?: PermissionType
    actions: ApplicationActionType[]
}
const GoogleGmailIntegrationForm: React.FC<IGoogleGmailIntegrationForm> = ({ actions }) => {
    const APP_UNIQUE_NAME = "GOOGLE_GMAIL"
    const { getAccessTokenSilently } = useAuth0()
    const [permissions, setPermissions] = useState<PermissionType[]>([])
    const [permission, setPermission] = useState<PermissionType>()
    const [createIntegrationLoading, setCreateIntegrationLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    // gets the existing permissions for the app
    useQuery({
        queryKey: [APP_UNIQUE_NAME],
        queryFn: async () => {
            const token = await getAccessTokenSilently()
            const permissionsResponse = await getAppPermissions(APP_UNIQUE_NAME, token)
            setPermissions(permissionsResponse)
            return permissionsResponse
        }
    })
    const form = useForm({
        initialValues: {
            name: "",
            description: "",
            permission: permissions ? permission : undefined,
            actions: [...actions]
        }
    })

    const onCheckActions = (changedAction: ApplicationActionType): void => {
        const actionNames = form.values.actions.map((action) => action.action)
        if (actionNames.includes(changedAction.action)) {
            form.setFieldValue("actions", form.values.actions.filter((action: ApplicationActionType) => action.action !== changedAction.action))
        } else {
            form.setFieldValue("actions", [...form.values.actions, changedAction])
        }
    }
    const submitIntegration = async (values: GoogleGmailIntegrationSubmissionBody): Promise<IntegrationType | undefined> => {
        // TODO: MAKE API CALL TO CREATE 
        try {
            const formattedTools: ApplicationActionType[] = formatActions(form.values.name, form.values.description, form.values.actions)
            const integration: CreateIntegrationType = {
                name: form.values.name,
                description: form.values.description,
                permission_id: values.permission?._id as string,
                config: {
                    id: "me"
                },
                tools: formattedTools
            }
            setCreateIntegrationLoading(true)
            const token = await getAccessTokenSilently()
            const response = await createIntegration(token, integration)
            notifications.show({
                title: <Text c="myColor">{`Successfully created ${form.values.name}`}</Text>,
                message: `Your ${form.values.name} has been created and is ready for use`,
                autoClose: 3000,
                color: "teal",
                icon: <IconCheck />
            })
            setCreateIntegrationLoading(false)
            return response
        } catch (error) {
            notifications.show({
                title: <Text c="myColor">{`Failed to create ${form.values.name}`}</Text>,
                message: `Your ${form.values.name} has not been integrated`,
                autoClose: 3000,
                color: "red",
                icon: <IconCross />
            })
            setCreateIntegrationLoading(false)
        }
    }
    const submitIntegrationRedirectToAgent = async (values: GoogleGmailIntegrationSubmissionBody) => {
        const response = await submitIntegration(values)
        navigate("/agents/create", { state: response })
    }
    return (
        <>
            <IntegrationHeader
                icon={<GoogleGmailImage />}
                title={"Google Gmail"}
            />
            <br></br>
            <form>
                <Stack gap={25}>
                    <div><GenericIntegrationTitle
                        title={"1. Name & Describe Integration"}
                        subtext={"Name & describe integration for your agents"}
                    />
                        <NameDescriptionInput
                            form={form}
                            textFormKey="name"
                            textAreaFormKey="description"
                            textLabel="Name"
                            textPlaceholder="Example: Work Email"
                            textAreaLabel="Description"
                            textAreaPlaceholder="Example: For managing work related emails"
                        />
                    </div>
                    <div>
                        <AddAccountHeader
                            title="2. Add Your Account"
                            subtext="Add your Google Gmail account to connect with your calendar"
                            oauthRoute="/google_gmail"
                        />
                        <>{permissions.length === 0 ? (
                            <NoPermissionsDisplay
                                text={"It seems you haven't created linked an account for this app yet, click the 'Add Account' above to start'"}
                                icon={<IoPersonAdd color="lightgray" style={{ width: 56, height: 56 }} />}
                            />) : (
                            <Stack my={10} gap={4}>
                                {permissions?.map((permission: any) => {
                                    return (
                                        <GenericRadioCard
                                            avatar={<Avatar src={permission.picture} size={"sm"} style={{ margin: "auto" }} />}
                                            title={<Text size="sm">{permission.userName}</Text>}
                                            subtext={permission.email}
                                            checked={form.values.permission?._id === permission._id}
                                            onChecked={() => {
                                                form.setFieldValue("permission", permission)
                                                setPermission(permission)
                                            }}
                                        />)
                                })}

                            </Stack>)}
                        </>
                    </div>
                    <Box display={form.values.permission == undefined ? "none" : "block"}>
                        <GenericIntegrationTitle
                            title="3. Confirm Actions"
                            subtext="Actions are generated from integrations, you can choose which actions you want your agent to use"
                        />
                        <Grid gutter={4} my={10}>
                            {
                                actions.map((action) => {
                                    return (
                                        <Grid.Col span={6}>
                                            <ActionCheckCard
                                                name={action.name}
                                                description={action.description}
                                                checked={form.values.actions.map(action => action.action).includes(action.action)}
                                                onChecked={() => onCheckActions(action)}
                                            />
                                        </Grid.Col>
                                    )
                                })
                            }
                        </Grid>

                    </Box>
                </Stack>
                <Flex mt={10} display={form.values.permission === undefined ? "none" : "block"}>
                    <div style={{ flexGrow: 1 }} />
                    <Group >
                        <Button
                            loading={createIntegrationLoading}
                            variant="light"
                            color="myColor"
                            onClick={async () => await submitIntegration(form.values)}>
                            Create Integration
                        </Button>
                        <Button color="myColor" onClick={async () => await submitIntegrationRedirectToAgent(form.values)}>Create Agent w/ Integration</Button>
                    </Group>
                </Flex>
            </form>

        </>)
}
export default GoogleGmailIntegrationForm


