import { Group, Text, em, rem } from "@mantine/core";
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from "@mantine/dropzone"
import { useMediaQuery } from "@mantine/hooks";
import { IconCheck, IconPhoto, IconUpload, IconX } from "@tabler/icons-react";
import { uploadFiles } from "../../../handler/files/fileHandlers";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { FileMessageType } from "../../../pages/chat/ChatPage";
import classes from "./dropzone.module.css"
import { notifications } from "@mantine/notifications";

interface IChatDropzone {
    setDropzoneVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setFiles: React.Dispatch<React.SetStateAction<FileMessageType[]>>;
}
const ChatDropzone: React.FC<IChatDropzone> = ({ setDropzoneVisible, setFiles }) => {
    const { getAccessTokenSilently } = useAuth0()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
    const onReject = () => {
        setDropzoneVisible(false)
    }
    const onDrop = async (event: FileWithPath[]) => {
        if (event.length > 10) {
            // disallow upload
            notifications.show({
                title: 'Upload Error',
                message: 'You can only upload up to 10 files at a time.',
                color: 'red',
                icon: <IconX />
            });
            return;
        }
        setIsLoading(true)
        const token = await getAccessTokenSilently()
        let files = await uploadFiles(token, event)
        for (let file of files) {
            if (["image/png", "image/jpeg", "image/jpg"].includes(file.contentType)) {
                const fileFormat = file.contentType
                console.log(fileFormat)
                file.contentType = "image"
                file["fileType"] = fileFormat

            } else {
                file.contentType = "document"
            }
        }
        setFiles(prevFiles => [...prevFiles, ...files] as FileMessageType[])
        setDropzoneVisible(false)
        setIsLoading(false)
    }
    return (<Dropzone
        maxSize={100000}
        classNames={classes}
        loading={isLoading}
        accept={["image/png",
            "image/jpeg",
            "image/jpg",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/msword",
            "application/pdf",
            "text/plain",
            "text/markdown",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
        ]}
        onReject={(event) => {
            setDropzoneVisible(false)
            console.log(event)
            notifications.show({
                title: 'Upload Error',
                message: `Error ${event[0].errors[0].message}`,
                color: 'red',
                icon: <IconCheck />
            });
        }}
        multiple
        style={{
            width: isMobile ? "100%" : "70%",
            height: "100%",
            flexGrow: 1,
            marginBlock: 30,
            display: "flex",
            alignItems: "center",
        }}

        onDrop={onDrop}
    >
        <Group style={{ display: "flex", alignItems: "center", margin: "auto" }}>
            <Dropzone.Accept>
                <IconUpload
                    style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                    stroke={1.5}
                />
            </Dropzone.Accept>
            <Dropzone.Reject>
                <IconX
                    style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
                    stroke={1.5}
                />
            </Dropzone.Reject>
            <Dropzone.Idle>
                <IconPhoto
                    style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' }}
                    stroke={1.5}
                />
            </Dropzone.Idle>

            <div >
                <Text size="xl" inline>
                    Drag files here
                </Text>
                <Text size="sm" c="dimmed" inline mt={7}>
                    Attach up to 10 files, each file should not exceed 100KB
                </Text>
            </div>
        </Group>

    </Dropzone>)
}
export default ChatDropzone