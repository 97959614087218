import { Card, Checkbox, Flex, Text } from "@mantine/core"

interface IActionCheckCard {
    name: string
    description: string
    onChecked: () => void
    checked: boolean
}

const ActionCheckCard: React.FC<IActionCheckCard> = ({ name, description, onChecked, checked }) => {
    return (
        <Card withBorder p={2} px={10}>
            <Flex gap={10}>
                <Checkbox
                    color="myColor" style={{ margin: "auto" }}
                    checked={checked}
                    onClick={onChecked}
                />
                <div style={{ width: "100%" }}>
                    <Text size="sm" fw={500} c="myColor">{name}</Text>
                    <Text size="xs" c="dimmed">{description}</Text>
                </div>
            </Flex>
        </Card>)
}
export default ActionCheckCard