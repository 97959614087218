import {
  Flex,
  ScrollArea,
  em,
  Stack,
  Divider,
  Text,
  Drawer,
  ColorSwatch,
  Tooltip,
} from "@mantine/core";
import ChatBox from "../../components/chat/ChatBox";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useEffect, useRef, useState } from "react";
import ChatDropzone from "../../components/chat/dropzone/ChatDropzone";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ConversationType,
  getConversation,
} from "../../handler/conversation/conversation";
import { MessageType } from "../../handler/message/message";
import ChatMessageBox from "../../components/chat/box/ChatMessageBox";
import ChatMessageUpdatingBox from "../../components/chat/box/ChatMessageUpdatingBox";
import EmptyConversationView from "../../components/conversation/EmptyConversationView";
import { getAgent } from "../../handler/agent/agent";
import { AgentAggregateType } from "../../types/agent/agent";
import RunDisplayDrawer from "../../components/run/RunDisplayDrawer";
import { getRun, Run } from "../../handler/run/run";

export type FileMessageType = {
  srcFilename: string;
  bucketFilename: string;
  size: number;
  contentType: "image" | "document";
  fileType?: string;
  metadata: Record<string, any>;
  createdOn: string;
};

const ChatPage = () => {
  const [runId, setRunId] = useState<string>();
  const [run, setRun] = useState<Run | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [messageStack, setMessageStack] = useState<Record<string, any>[]>([]);
  const [conversation, setConversation] = useState<ConversationType>();
  const [openedRunDisplay, { open: openRunDisplay, close: closeRunDisplay }] =
    useDisclosure(false);
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const [files, setFiles] = useState<FileMessageType[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams<{ id: string }>();
  const endOfLineRef = useRef<HTMLDivElement>(null);
  const [agent, setAgent] = useState<AgentAggregateType | undefined>(undefined);
  const navigate = useNavigate()
  // You can use this ID for fetching chat data or other purposes
  useEffect(() => {
    endOfLineRef.current?.scrollIntoView();
  }, [conversation]);
  const handleDropEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsHovered(false); // Reset hover state on drop
  };
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsHovered(true);
  };
  const { refetch, data, isLoading } = useQuery({
    queryKey: [id],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const response = await getConversation(token, id as string);
      setConversation(response);

      return response;
    },
  });
  useEffect(() => {
    const fetchAgent = async () => {
      if (conversation) {
        const token = await getAccessTokenSilently();
        const response = await getAgent(token, conversation.agentId as string);
        setAgent(response);
      }
    };

    fetchAgent();
  }, [conversation, getAccessTokenSilently]);
  const getMessageRun = async (runId: string) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await getRun(token, runId);
      setRun(response);
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("An unknown error occurred");
      }
    }
  };
  useEffect(() => {
    if (runId) {
      getMessageRun(runId);
    }
  }, [runId]);
  return (
    <>
      <Flex
        align="center"
        direction={"column"}
        style={{
          height: "100vh",
          marginTop: "-100px",
          paddingTop: "95px",
        }}
        onDragOver={handleDragOver}
        onDragEnter={handleDragOver}
        onDragExit={handleDropEnd}
        onDragEnd={handleDropEnd}
        onDragLeave={handleDropEnd}
      >
        {conversation && conversation.messages.length === 0 ? (<></>) : (
          <Flex my={4}>
            <Tooltip label={`Click to Edit "${agent?.name}"`} openDelay={500}>
              <ColorSwatch color={agent?.metadata.agentColor} withShadow onClick={() => {
                navigate(`/agents/${agent?._id}`)
              }} />
            </Tooltip>
          </Flex>)}
        {isHovered ? (
          <ChatDropzone setDropzoneVisible={setIsHovered} setFiles={setFiles} />
        ) : (
          <ScrollArea
            style={{
              width: isMobile ? "100%" : "70%",
              height: "100%",
              flexGrow: 1,
            }}
          >
            <Stack gap={16} px={12}>
              {/* add chat messages here */}
              <>
                {conversation && conversation.messages.length === 0 ? (
                  <EmptyConversationView agent={agent as AgentAggregateType} />
                ) : (
                  <></>
                )}
              </>
              {conversation && conversation?.messages.length > 0 ? (
                <>
                  {conversation?.messages.map(
                    (message: MessageType, index: number) => {
                      return (
                        <>
                          <ChatMessageBox
                            setRunId={setRunId}
                            isLast={index === conversation.messages.length - 1}
                            ref={endOfLineRef}
                            message={message}
                            onOpenRunDisplay={openRunDisplay}
                          />
                          {index !== conversation.messages.length - 1 ? (
                            <Divider mt={8} />
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    }
                  )}
                </>
              ) : (
                <></>
              )}

              <ChatMessageUpdatingBox
                visible={loading}
                ref={endOfLineRef}
                messageStack={messageStack}
              />
              <div ref={endOfLineRef}></div>
            </Stack>
          </ScrollArea>
        )}

        <ChatBox
          setMessageStack={setMessageStack}
          endRef={endOfLineRef}
          refetch={refetch}
          setLoading={setLoading}
          loading={loading}
          files={files}
          setFiles={setFiles}
          conversation={conversation as ConversationType}
          setConversation={setConversation}
        />
      </Flex>
      <Drawer
        title={
          <>
            <Text c="myColor" fw={600}>
              Chain of Thought
            </Text>
            <Text c={"dimmed"} size="sm">
              Agent's message reasoning
            </Text>
          </>
        }
        opened={openedRunDisplay}
        position="right"
        onClose={closeRunDisplay}
      >
        <RunDisplayDrawer run={run} />
      </Drawer>
    </>
  );
};
export default ChatPage;
