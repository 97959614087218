import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Paper,
  ScrollArea,
  Select,
  SelectProps,
  Skeleton,
  Stack,
  Text,
} from "@mantine/core";
import NotionImage from "../../../../../components/icons/NotionImage";
import IntegrationHeader from "../../../../../components/integration/header/IntegrationPermissionsList";
import GenericIntegrationTitle from "../../../../../components/title/GenericIntegrationTitle";
import NameDescriptionInput from "../../../../../components/integration/input/NameDescriptionInput";
import { useForm } from "@mantine/form";
import AddAccountHeader from "../../../../../components/integration/header/google/AddAccountHeader";
import { useEffect, useState } from "react";
import { PermissionType } from "../../../../../types/permission/permission";
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import NoPermissionsDisplay from "../../../../../components/permission/display/NoPermissionsDisplay";
import { IoCheckmark, IoDocument, IoPersonAdd } from "react-icons/io5";
import GenericRadioCard from "../../../../../components/integration/card/GenericRadioCard";
import { getAppPermissions } from "../../../../../handler/permission/permissionHandler";
import {
  NotionDisplayType,
  NotionEntityType,
  NotionIntegrationConfigType,
} from "../../../../../types/integration/apps/notion/notion";
import { getNotionPagesAndDatabases } from "../../../../../handler/apps/notion/notionHandler";
import { FaDatabase } from "react-icons/fa";
import { RiNotionFill } from "react-icons/ri";
import ActionCheckCard from "../../../../../components/integration/action/ActionCheckCard";
import { ApplicationActionType } from "../../../../../types/integration/action/action";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { createIntegration } from "../../../../../handler/integration/integration";
import {
  CreateIntegrationType,
  IntegrationType,
} from "../../../../../types/integration/integration";
import { formatActions } from "../../../../../helper/integration/tool";
const notionPageDatabaseIcons: Record<string, React.ReactNode> = {
  page: <IoDocument style={{ color: "lightgray" }} />,
  database: <FaDatabase style={{ color: "lightgray" }} />,
};

const renderSelectOption: SelectProps["renderOption"] = ({
  option,
  checked,
}) => (
  <Group flex="1" gap="xs">
    {notionPageDatabaseIcons[JSON.parse(option.value)["type"]]}
    {option.label}
    {checked && (
      <IoCheckmark style={{ marginInlineStart: "auto", color: "lightgray" }} />
    )}
  </Group>
);
interface INotionIntegrationForm {
  actions: ApplicationActionType[];
}

type NotionIntegrationSubmissionBody = {
  name: string;
  description: string;
  permission: PermissionType | undefined;
  notion: NotionDisplayType;
  actions: ApplicationActionType[];
};

const NotionIntegrationForm: React.FC<INotionIntegrationForm> = ({
  actions,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [createIntegrationLoading, setCreateIntegrationLoading] =
    useState<boolean>(false);
  const [permissions, setPermissions] = useState<PermissionType[]>([]);
  const [notionLoading, setNotionLoading] = useState<boolean>(false);
  const [notionEntities, setNotionEntities] = useState<NotionDisplayType[]>();
  const [selectedEntity, setSelectedEntity] = useState<NotionDisplayType>();
  const [permission, setPermission] = useState<PermissionType>();
  const [listedActions, setListedActions] = useState<ApplicationActionType[]>(
    []
  );
  const navigate = useNavigate();
  const APP_UNIQUE_NAME = "NOTION";
  const form = useForm({
    initialValues: {
      name: "",
      description: "",
      permission: permission ? permission : undefined,
      notion: {} as NotionDisplayType,
      actions: [...listedActions],
    },
  });
  const onCheckActions = (changedAction: ApplicationActionType): void => {
    const actionNames = form.values.actions.map((action) => action.action);
    if (actionNames.includes(changedAction.action)) {
      form.setFieldValue(
        "actions",
        form.values.actions.filter(
          (action: ApplicationActionType) =>
            action.action !== changedAction.action
        )
      );
    } else {
      form.setFieldValue("actions", [...form.values.actions, changedAction]);
    }
  };
  useQuery({
    queryKey: [APP_UNIQUE_NAME],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const permissionsResponse = await getAppPermissions(
        APP_UNIQUE_NAME,
        token
      );
      setPermissions(permissionsResponse);
      return permissionsResponse;
    },
  });
  useEffect(() => {
    const getNotionPagesDatabases = async () => {
      if (permission) {
        setNotionLoading(true);
        try {
          const token = await getAccessTokenSilently();
          form.setFieldValue("notion", {} as NotionDisplayType);
          let notionList: NotionDisplayType[] =
            await getNotionPagesAndDatabases(permission._id, token);
          // removed db for now cos it sucks
          notionList = notionList.filter((item) => item.type !== "database");
          setNotionEntities(notionList);
          setSelectedEntity(undefined);
          setNotionLoading(false);
        } catch (e) {
          // TODO: add notification
          setNotionLoading(false);
        }
      }
    };
    getNotionPagesDatabases();
  }, [permission]);
  const submitIntegration = async (
    values: NotionIntegrationSubmissionBody
  ): Promise<IntegrationType | undefined> => {
    try {
      const token = await getAccessTokenSilently();
      const formattedTools: ApplicationActionType[] = formatActions(
        values.name,
        values.description,
        values.actions
      );
      const integration: CreateIntegrationType = {
        name: values.name,
        description: values.description,
        permission_id: values.permission?._id as string,
        config: {
          id: values.notion.id,
          type: form.values.notion.type,
        } as NotionIntegrationConfigType,
        tools: formattedTools,
      };

      setCreateIntegrationLoading(false);
      notifications.show({
        title: (
          <Text c="myColor">{`Successfully created ${form.values.name}`}</Text>
        ),
        message: `Your ${form.values.name} has been created and is ready for use`,
        autoClose: 3000,
        color: "teal",
        icon: <IconCheck />,
      });
      const response = await createIntegration(token, integration);
      return response;
    } catch (error) {
      notifications.show({
        title: (
          <Text c="myColor">{`Failed to create ${form.values.name}`}</Text>
        ),
        message: `Your ${form.values.name} has not been integrated`,
        autoClose: 3000,
        color: "red",
        icon: <IconX />,
      });
    }
  };
  useEffect(() => {
    if (selectedEntity) {
      const actionMap: Record<"page" | "database", ApplicationActionType[]> = {
        page: [
          {
            name: "Get Page",
            description: "Gets full Notion Page content",
            action: "NOTION_PAGE_GET",
          },
        ],
        database: [
          {
            name: "Search Database",
            description: "Searches Notion Database based on text",
            action: "NOTION_DATABASE_SEARCH",
          },
        ],
      };
      const selectedActions = actionMap[selectedEntity.type];
      form.setFieldValue("actions", selectedActions);
      setListedActions(selectedActions);
    }
  }, [selectedEntity]);
  return (
    <>
      <IntegrationHeader icon={<NotionImage />} title="Notion" />
      <br></br>
      <form>
        <Stack gap={25}>
          <div>
            <GenericIntegrationTitle
              title={"1. Name & Describe Integration"}
              subtext={"Name & describe integration for your agents"}
            />
            <NameDescriptionInput
              form={form}
              textFormKey="name"
              textAreaFormKey="description"
              textLabel="Name"
              textPlaceholder="Example: My Notes Page"
              textAreaLabel="Description"
              textAreaPlaceholder="Example: For reading study notes"
            />
          </div>
          <div>
            <AddAccountHeader
              oauthRoute={"/notion"}
              title={"2. Add Your Account"}
              subtext={"Add your Notion account to connect with your Pages"}
            />

            {permissions === undefined ? (
              <NoPermissionsDisplay
                text={
                  "It seems you haven't created linked an account for this app yet, click the 'Add Account' above to start'"
                }
                icon={
                  <IoPersonAdd
                    color="lightgray"
                    style={{ width: 56, height: 56 }}
                  />
                }
              />
            ) : (
              <Stack my={10} gap={4}>
                {permissions?.map((permission: any) => {
                  return (
                    <GenericRadioCard
                      avatar={
                        <Avatar
                          src={permission.picture}
                          size={"sm"}
                          style={{ margin: "auto" }}
                        />
                      }
                      title={<Text size="sm">{permission.userName}</Text>}
                      subtext={permission.email}
                      checked={form.values.permission?._id === permission._id}
                      onChecked={() => {
                        form.setFieldValue("permission", permission);
                        setPermission(permission);
                      }}
                    />
                  );
                })}
              </Stack>
            )}
          </div>
          <Box display={form.values.permission == undefined ? "none" : "block"}>
            <GenericIntegrationTitle
              title="3. Choose Notion Page/Database"
              subtext="Choose the page or database you want to connect to"
            />
            {notionEntities === undefined ? (
              <Paper
                withBorder
                my={10}
                mih={100}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Skeleton visible={notionLoading}>
                  <Flex
                    w={400}
                    direction={"column"}
                    align={"center"}
                    gap={10}
                    p={20}
                  >
                    {/* <IoCalendar color="lightgray" style={{ width: 56, height: 56, }} /> */}
                    <Text size="xs" c="dimmed" style={{ textAlign: "center" }}>
                      It seems you haven't created a calendar in Google Calendar
                      yet
                    </Text>
                  </Flex>
                </Skeleton>
              </Paper>
            ) : (
              <Skeleton visible={notionLoading}>
                <Select
                  placeholder="Search Notion..."
                  leftSection={
                    selectedEntity ? (
                      notionPageDatabaseIcons[selectedEntity.type]
                    ) : (
                      <RiNotionFill />
                    )
                  }
                  color="myColor"
                  style={{ color: "#3a4bdd" }}
                  mt={4}
                  searchable
                  disabled={notionEntities.length === 0}
                  data={notionEntities.map((item) => {
                    return { label: item.title, value: JSON.stringify(item) };
                  })}
                  renderOption={renderSelectOption}
                  onChange={(event) => {
                    let value = JSON.parse(event as string);
                    form.setFieldValue("notion", value);
                    setSelectedEntity(value);
                  }}
                  limit={5}
                  nothingFoundMessage={<>Nothing found on Notion</>}
                />
              </Skeleton>
            )}
          </Box>
          <Box
            display={
              Object.keys(form.values.notion).length === 0 ? "none" : "block"
            }
          >
            <GenericIntegrationTitle
              title="4. Confirm Actions"
              subtext="Actions are generated from integrations, you can choose which actions you want your agent to use"
            />
            <Grid gutter={4} my={10}>
              {listedActions.map((action) => {
                return (
                  <Grid.Col span={6}>
                    <ActionCheckCard
                      name={action.name}
                      description={action.description}
                      checked={form.values.actions
                        .map((action) => action.action)
                        .includes(action.action)}
                      onChecked={() => onCheckActions(action)}
                    />
                  </Grid.Col>
                );
              })}
            </Grid>
          </Box>
          <Flex
            mt={10}
            display={
              Object.keys(form.values.notion).length === 0 ? "none" : "block"
            }
          >
            <div style={{ flexGrow: 1 }} />
            <Group>
              <Button
                // loading={createIntegrationLoading}
                variant="light"
                color="myColor"
                onClick={async () => {
                  await submitIntegration(form.values);
                  navigate("/integrations");
                }}
              >
                Create Integration
              </Button>
              <Button
                color="myColor"
                onClick={async () => {
                  const integration = await submitIntegration(form.values);
                  navigate("/agents/create", { state: integration });
                }}
              >
                Create Agent w/ Integration
              </Button>
            </Group>
          </Flex>
        </Stack>
      </form>
    </>
  );
};

export default NotionIntegrationForm;
