import { Flex, Grid, Paper, Text, ActionIcon, Tooltip } from "@mantine/core";
import GoogleCalendarImage from "../../icons/GoogleCalendarImage";
import GoogleGmailImage from "../../icons/GoogleGmailImage";
import NotionImage from "../../icons/NotionImage";
import {
  IoArrowForward,
  IoCalculator,
  IoCalendar,
  IoDocument,
  IoEllipsisVerticalSharp,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const defaultIconStyles = { color: "lightgray", width: 32, height: 32 };

export const ImageIconMap: Record<string, React.ReactNode> = {
  GOOGLE_CALENDAR: <GoogleCalendarImage width={32} height={32} />,
  GOOGLE_GMAIL: <GoogleGmailImage width={32} height={32} />,
  NOTION: <NotionImage width={32} height={32} />,
  ONWORD_DOCUMENT_SEARCH: <IoDocument style={{ ...defaultIconStyles }} />,
  ONWORD_CALCULATOR: <IoCalculator style={{ ...defaultIconStyles }} />,
  ONWORD_DATE_COUNTER: <IoCalendar style={{ ...defaultIconStyles }} />,
};

interface IIntegrationCard {
  id: string;
  name: string;
  applicationName: string;
  description: string;
}

const IntegrationCard: React.FC<IIntegrationCard> = ({
  name,
  applicationName,
  id,
  description,
}) => {
  const navigate = useNavigate();
  return (
    <Grid.Col span={3}>
      <Paper
        onClick={() => navigate(`/integrations/${id}`)}
        style={{
          cursor: "pointer",
          border: "1px solid #E2E8F0",
          overflow: "hidden",
        }}
        p={8}
        radius={8}
      >
        <Flex gap={10} direction={"column"}>
          <>{ImageIconMap[applicationName]}</>
          <Flex direction="column">
            <Text
              size="sm"
              truncate
              fw={500}
              style={{
                color: "#3a4bdd",
                cursor: "pointer",
                width: "100%",
                margin: "auto",
              }}
            >
              {name}
            </Text>
            <Text
              size="xs"
              truncate
              c={"dimmed"}
              style={{
                cursor: "pointer",
                width: "100%",
                margin: "auto",
              }}
            >
              {description}
            </Text>
            {/* <Text size="xs" c="dimmed" truncate>{description}</Text> */}
          </Flex>
          {/* <ActionIcon w={48} style={{ margin: "auto" }} variant="subtle" color="myColor">
                        <IoArrowForward />
                    </ActionIcon> */}
        </Flex>
      </Paper>
    </Grid.Col>
  );
};

export default IntegrationCard;
