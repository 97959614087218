import {
  Alert,
  Button,
  Flex,
  Group,
  Menu,
  Modal,
  rem,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { IoPencil, IoTrashBin } from "react-icons/io5";
import {
  ConversationViewType,
  deleteConversation,
  renameConversation,
} from "../../../handler/conversation/conversation";
import { useDisclosure } from "@mantine/hooks";
import { IconCheck, IconExclamationMark, IconX } from "@tabler/icons-react";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

interface FrameConversationDropdownMenuProps {
  hoverRef: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  conversation: ConversationViewType;
  trigger: () => void;
}

const FrameConversationDropdownMenu: React.FC<
  FrameConversationDropdownMenuProps
> = ({ hoverRef, children, conversation, trigger }) => {
  const form = useForm({
    initialValues: {
      chatName: conversation.name
    },
    validate: {
      chatName: (value: string) => value === undefined || value.length === 0
    }
  })
  const [opened, { open, close }] = useDisclosure(false);
  const [isRenameModalOpened, { open: openRenameModal, close: closeRenameModal }] = useDisclosure(false);
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState<boolean>(false);
  const onDelete = async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      await deleteConversation(token, conversation._id);
      await trigger();
      setLoading(false);
      close();
      notifications.show({
        title: "Successfully deleted",
        message: `Successfully deleted ${conversation.name}`,
        delay: 3000,
        color: "teal",
        icon: <IconCheck />
      });
    }
    catch (error) {
      notifications.show({
        title: "Error",
        message: "Failed to delete conversation. Please try again.",
        delay: 3000,
        color: "red",
        icon: <IconX />
      });
    }
  };
  const onRename = async (): Promise<void> => {
    try {
      if (form.isDirty()) {
        if (form.validate().hasErrors) throw new Error("New chat name is not valid")
        const token = await getAccessTokenSilently()
        await renameConversation(token, conversation._id, form.values.chatName)
        await trigger()
        notifications.show({
          title: "Successfully updated",
          message: `Successfully updated to ${form.values.chatName}`,
          delay: 3000,
          color: "teal",
          icon: <IconCheck />
        });
        closeRenameModal()
      }
    } catch (error) {
      notifications.show({
        title: "Error",
        message: error instanceof Error ? error.message : "An unknown error occurred",
        delay: 3000,
        color: "red",
        icon: <IconX />
      });
    }
  }
  return (
    <div ref={hoverRef}>
      <Menu shadow="md" width={150}>
        <Menu.Target>{children}</Menu.Target>

        <Menu.Dropdown pr={6}>
          <Menu.Label>Manage Chat</Menu.Label>

          <Menu.Item leftSection={<IoPencil />} onClick={openRenameModal}>Rename</Menu.Item>

          <Menu.Divider />

          <Menu.Label>Danger zone</Menu.Label>
          <Menu.Item
            onClick={open}
            color="red"
            leftSection={
              <IoTrashBin style={{ width: rem(14), height: rem(14) }} />
            }
          >
            Delete Chat
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <Modal
        opened={opened}
        onClose={close}
        centered
        title={
          <>
            <Text c="red" fw={600} size="lg">
              Delete Chat?
            </Text>
          </>
        }
        withCloseButton={false}
      >
        <Stack gap={25}>
          <Flex gap={4} direction={"column"}>
            <Alert color="red" title={"Warning"} icon={<IconExclamationMark />}>
              By confirming this action you will permanently delete chat.
            </Alert>
            <Text size="sm" c={"dimmed"}>
              The agent associated with the chat will not be affected.
            </Text>
          </Flex>
          <Flex>
            <div style={{ flexGrow: 1 }} />
            <Group align="right">
              <Button onClick={close} color="gray" variant="subtle">
                Cancel
              </Button>
              <Button
                color="red"
                leftSection={<IoTrashBin />}
                onClick={async () => onDelete()}
              >
                Confirm
              </Button>
            </Group>
          </Flex>
        </Stack>
      </Modal>
      <Modal
        opened={isRenameModalOpened}
        onClose={closeRenameModal}
        centered
        title={<>
          <Text fw={600} c="myColor">Rename Chat</Text>
          <Text c={"dimmed"} size="sm">Give chat a more appropriate name</Text>
        </>}
        withCloseButton={false}
      >
        <Stack gap={25}>
          <TextInput
            key={form.key("chatName")}
            {...form.getInputProps("chatName")}
            placeholder="New Chat Name"
          >

          </TextInput>
          <Flex>
            <div style={{ flexGrow: 1 }} />
            <Group align="right">
              <Button onClick={closeRenameModal} color="gray" variant="subtle">
                Cancel
              </Button>
              <Button
                color="myColor"
                disabled={!form.isDirty()}
                onClick={async () => await onRename()}
              >
                Confirm
              </Button>
            </Group>
          </Flex>
        </Stack>
      </Modal>
    </div>
  );
};

export default FrameConversationDropdownMenu;
