import { useForm } from "@mantine/form"
import { AgentAggregateType, AgentPut } from "../../types/agent/agent"
import { IntegrationType } from "../../types/integration/integration"
import { Accordion, Alert, Box, Text, Button, ColorInput, ColorSwatch, Divider, Flex, MultiSelect, NumberInput, Select, Skeleton, Slider, Stack, Textarea, TextInput } from "@mantine/core"
import IntegrationHeader from "../../components/integration/header/IntegrationPermissionsList"
import { QueryObserverResult, RefetchOptions, useQuery } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { getAllIntegrations } from "../../handler/integration/integration"
import { IoAdd, IoChatbox, IoFlask, IoLink, IoPencil, IoWarning } from "react-icons/io5"
import classes from "./multiselectOption.module.css"
import { renderMultiSelectOption, renderSelectOption } from "./CreateAgentForm"
import { models } from "./CreateAgentPage"
import { updateAgent } from "../../handler/agent/agent"
import { notifications } from "@mantine/notifications"
import { IconCheck, IconX } from "@tabler/icons-react"
import { useNavigate } from "react-router-dom"

interface IEditAgentForm {
    agent: AgentAggregateType
    refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<AgentAggregateType, Error>>
}


const EditAgentForm: React.FC<IEditAgentForm> = ({ agent, refetch }) => {
    const { getAccessTokenSilently } = useAuth0()
    const navigate = useNavigate()
    const form = useForm({
        initialValues: {
            name: agent.name,
            systemPrompt: agent.systemPrompt,
            temperature: agent.temperature,
            maxIterations: agent.maxIterations,
            agentColor: agent.metadata.agentColor,
            integrations: agent.integrations.map((item) => JSON.stringify(item)),
            model: models.filter((model) => JSON.parse(model.value).model === agent.model)[0].value
        },
        validate: {
            name: (value) => value === "",
            systemPrompt: (value) => value === "",
            temperature: (value) => (value < 0 || value > 1),
            maxIterations: (value) => (value < 10 || value > 30),
            model: (value) => !models.map((model) => JSON.parse(model.value).model).includes(JSON.parse(value).model)
        }
    })
    const { data, isLoading } = useQuery({
        queryKey: [],
        queryFn: async () => {
            const token = await getAccessTokenSilently()
            const integrationsResponse = await getAllIntegrations(token)
            return integrationsResponse
        }
    })
    const onSubmit = async () => {
        try {
            const token = await getAccessTokenSilently()
            if (form.validate().hasErrors) {
                throw new Error("Form values are not valid")
            }
            const updateAgentBody: AgentPut = {
                name: form.values.name,
                model: JSON.parse(form.values.model).model,
                systemPrompt: form.values.systemPrompt,
                integrations: form.values.integrations.map((integration) => JSON.parse(integration)._id),
                maxIterations: form.values.maxIterations,
                tools: form.values.integrations.flatMap((item) => JSON.parse(item).tools),
                metadata: { agentColor: form.values.agentColor }
            }
            await updateAgent(token, agent._id, updateAgentBody)
            notifications.show({
                title: "Successfully updated",
                message: `Updated ${form.values.name}`,
                icon: <IconCheck />,
                color: "teal"
            })
            refetch()
            navigate(-1)
        } catch (error) {
            notifications.show({
                title: "There was an error",
                message: `Error: ${error}`,
                icon: <IconX />,
                color: "red"
            })
        }
    }
    return (
        <Box maw={600} m={"auto"} mt={25}>
            <IntegrationHeader
                icon={<></>}
                title={`Edit ${agent.name}`}
            />
            <Stack gap={25} mt={12}>
                <TextInput
                    label={"Name"}
                    required
                    style={{ color: '#3a4bdd' }}
                    placeholder="Example: Calendar Agent"
                    withAsterisk description="Give your agent a name that describes it's role"
                    key={form.key('name')}
                    {...form.getInputProps('name')}
                />
                <Textarea
                    label="System Prompt"
                    required
                    withAsterisk
                    description="Give your agent descriptions on how it should function (example: Use calendar tool to...)"
                    placeholder="You are a helpful assistant..."
                    style={{ color: '#3a4bdd' }}
                    key={form.key('systemPrompt')}
                    {...form.getInputProps('systemPrompt')}
                    rows={5}

                />
                <Skeleton visible={isLoading}>
                    <MultiSelect
                        classNames={classes}
                        leftSection={<IoLink />}
                        searchable
                        maxValues={5}
                        color="myColor"
                        disabled={isLoading}
                        label={"Add Integration"}
                        style={{ color: '#3a4bdd' }}
                        description="Allow agents to perform actions on your apps"
                        placeholder="Example: Work Calendar"
                        renderOption={renderMultiSelectOption}
                        data={data?.map((integration: IntegrationType) => {
                            return { label: integration.name, value: JSON.stringify(integration) }
                        })}
                        key={form.key('integrations')}
                        {...form.getInputProps('integrations')}
                        nothingFoundMessage={<Button 
                            leftSection={<IoAdd />}
                            onClick={()=>navigate("/integrations")}
                            color="myColor" variant="light">Create New Integration</Button>}
                        defaultValue={agent.integrations.map((item) => JSON.stringify(item))}
                    />
                </Skeleton>
                <Accordion>
                    <Accordion.Item key={"model-design"} value={"model-design"}>
                        <Accordion.Control style={{ color: '#3a4bdd' }} icon={<ColorSwatch color={form.values.agentColor} withShadow />}>
                            Agent Design
                        </Accordion.Control>
                        <Accordion.Panel>
                            <ColorInput
                                label={<Text color="myColor" fw={500} size="sm">Agent Color</Text>}
                                description="Give your agent a color to easily identify it"
                                placeholder="#FFFFFF"
                                defaultValue={form.values.agentColor}
                                key={form.key('agentColor')}
                                {...form.getInputProps('agentColor')}
                            />
                        </Accordion.Panel>
                    </Accordion.Item>
                    <Accordion.Item key={"model-settings"} value={"model-settings"}>
                        <Accordion.Control style={{ color: '#3a4bdd' }} icon={<IoFlask style={{ width: 24, height: 24, marginLeft: 3 }} />}>Advanced Settings</Accordion.Control>
                        <Accordion.Panel>
                            <Divider my={8} />
                            <Alert icon={<IoWarning />} color="yellow" variant="light">
                                <Text size="xs" fw={500} mt={0} c="">Warning!</Text>
                                <Text size="xs" c={"dimmed"} mt={0}>Changing these parameters can result in unexpected agent behaviour</Text>
                            </Alert>
                            <Stack gap={16} mt={12}>
                                <Select
                                    style={{ width: "100%" }}
                                    classNames={classes}
                                    label={<Text fw={500} size="sm" style={{ color: '#3a4bdd' }}>Model Preference</Text>}
                                    description="Choice of LLM that agent uses, this may change response quality"
                                    renderOption={renderSelectOption}
                                    defaultChecked={true}
                                    defaultValue={form.values.model}
                                    data={models}
                                    key={form.key('model')}
                                    {...form.getInputProps('model')}

                                />
                                <Stack gap={2}>
                                    <Flex gap={2} direction={"column"}>
                                        <Text fw={500} size="sm" style={{ color: '#3a4bdd' }}>Model Temperature</Text>
                                        <Text size="xs" c={"dimmed"}>Controls the randomness of agent's response</Text>
                                    </Flex>
                                    <Slider
                                        color="myColor"
                                        min={0}
                                        max={1}
                                        step={0.0005}
                                        defaultValue={form.values.temperature}
                                        key={form.key('temperature')}
                                        {...form.getInputProps('temperature')}
                                    />
                                </Stack>
                                <NumberInput
                                    label={<Text fw={500} size="sm" style={{ color: '#3a4bdd' }}>Agent Maximum Iterations</Text>}
                                    description="Maximum number of iterations agent has during chain of thought, this will affect the number of actions an agent can perform on a single prompt"
                                    min={10}
                                    max={50}
                                    defaultValue={form.values.maxIterations}
                                    key={form.key('maxIterations')}
                                    {...form.getInputProps('maxIterations')}
                                />
                            </Stack>

                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

            </Stack>
            <Stack mt={8}>
                <Text c="dimmed" size="xs" mt={0}>
                    Disclaimer: Large language models can sometimes produce incorrect or misleading information, known as hallucinations.
                </Text>
                <Flex gap={8}>
                    <div style={{ flexGrow: 1 }}></div>
                    <Button
                        variant="light"
                        color="myColor"
                        onClick={form.reset}
                        disabled={!form.isDirty()}

                    >
                        Reset</Button>
                    <Button
                        color="myColor"
                        disabled={!form.isDirty()}
                        onClick={async () => {
                            await onSubmit()
                        }}
                    >
                        Confirm</Button>
                </Flex>
            </Stack>
        </Box>
    )
}

export default EditAgentForm