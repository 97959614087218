import axios from 'axios';
import { AgentAggregateType, AgentPost, AgentPut, AgentType } from '../../types/agent/agent';
import { MONOLITH_ROUTE } from '../routes';
import { ConversationType } from '../conversation/conversation';

export const createAgent = async (token: string, agentData: AgentPost): Promise<AgentType> => {
    try {
        const response = await axios.post(`${MONOLITH_ROUTE}/agents/create`,
            agentData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response.data as AgentType;
    } catch (error) {
        console.error("Error creating agent:", error);
        throw new Error("There was an error with creating agent");
    }
};


export const createAgentAndConversation = async (token: string, agentData: AgentPost): Promise<ConversationType> => {
    try {
        const response = await axios.post(`${MONOLITH_ROUTE}/conversations/create/agent`,
            agentData,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response.data as ConversationType;
    } catch (error) {
        console.error("Error creating agent & conversation:", error);
        throw new Error("There was an error with creating agent & conversation");
    }
};



export const getAllAgents = async (token: string): Promise<AgentType[]> => {
    try {
        const response = await axios.get(`${MONOLITH_ROUTE}/agents`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        return response.data.sort((a: AgentType, b: AgentType) => new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime()) as AgentType[];
    } catch (error) {
        console.error(error)
        throw new Error("There was an error with getting all agents")
    }
}

export const getAgent = async (token: string, agentId: string): Promise<AgentAggregateType> => {
    try {
        const response = await axios.get(`${MONOLITH_ROUTE}/agent/${agentId}/aggregate`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        return response.data as AgentAggregateType

    }
    catch (error) {
        console.error(error)
        throw new Error(`There was an error with getting agent with ID: ${agentId}`)
    }
}

export const deleteAgent = async (token: string, agentId: string): Promise<void> => {
    try {
        await axios.delete(`${MONOLITH_ROUTE}/agents/${agentId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )

    }
    catch (error) {
        console.error(error)
        throw new Error(`There was an error with getting agent with ID: ${agentId}`)
    }
}

export const updateAgent = async (token: string, agentId: string, updateBody: AgentPut) => {
    try {
        const response = await axios.put(`${MONOLITH_ROUTE}/agents/${agentId}/update`,
            updateBody,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
        if (response.status !== 200) {
            throw new Error("The agent was not updated")
        }
    } catch (error) {
        console.error(error)
        throw new Error(`There was an error with updating agent with ID: ${agentId}`)
    }
}