import { Card, Flex, Text } from "@mantine/core"

interface ActionDisplayCard {
    name: string
    description: string
    action: string
}

const ActionDisplayCard: React.FC<ActionDisplayCard> = ({ name, description }) => {
    return (
        <Card withBorder p={10} px={10} mih={80}>
            <Flex gap={10}>

                <div style={{ width: "100%" }}>
                    <Text size="sm" fw={500} c="myColor">{name}</Text>
                    <Text size="xs" c="dimmed">{description}</Text>
                </div>
            </Flex>
        </Card>)
}
export default ActionDisplayCard