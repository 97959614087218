import { Code, Flex, Grid, Paper, Text } from "@mantine/core"
import { IoDocumentSharp, IoImage } from "react-icons/io5"

interface IChatFilePill {
    filename: string
    contentType: "document" | "image"

}

const ChatFilePill: React.FC<IChatFilePill> = ({ filename, contentType }) => {
    return (<Grid.Col span={4}>
        <Paper style={{ width: "100%" }} withBorder p={4} >
            <Flex gap={8}>
                {contentType === "document" ? (<IoDocumentSharp color="myColor" style={{ margin: "auto", width: 32, height: 32 }} />) : (<></>)}
                {contentType === "image" ? (<IoImage color="myColor" style={{ margin: "auto", width: 32, height: 32 }} />) : (<></>)}
                <Text truncate c={"dimmed"} style={{ margin: "auto" }}>
                    <Code>{filename}</Code>
                </Text>
            </Flex>
        </Paper>
    </Grid.Col>)

}
export default ChatFilePill