import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { deleteIntegration, getAllIntegrations, getIntegration } from "../../handler/integration/integration";
import { Box, Button, Flex, Grid, Group, Modal, Skeleton, Stack, Text, TextInput } from "@mantine/core";
import IntegrationHeader from "../../components/integration/header/IntegrationPermissionsList";
import { ImageIconMap } from "../../components/integration/card/IntegrationCard";
import { integrationIconMap } from "../agent/CreateAgentForm";
import { ActionDescriptionMap } from "../../components/integration/map/IntegrationActionDescriptionMap";
import ActionDisplayCard from "../../components/integration/action/ActionDisplayCard";
import { IoTrash } from "react-icons/io5";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";

const IntegrationViewPage = () => {
    const { getAccessTokenSilently } = useAuth0()
    const [opened, { open, close }] = useDisclosure(false);
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate()
    const { data, isLoading, refetch } = useQuery({
        queryKey: [id],
        queryFn: async () => {
            const token = await getAccessTokenSilently()
            const integrationsResponse = await getIntegration(token, id as string)
            return integrationsResponse
        }
    })
    const form = useForm({
        initialValues: {
            deleteConfirmText: ""
        },
        validate: {
            deleteConfirmText: (value) => value !== data?.name
        }
    })
    const onConfirm = async () => {
        if (form.validate().hasErrors) {
            throw new Error(`Mismatch value, type '${data?.name}' to delete`)
        }
        try {
            const token = await getAccessTokenSilently()
            await deleteIntegration(token, id as string)
            notifications.show({
                title: "Successful Deletion",
                message: `Successfully deleted ${data?.name}`,
                icon: <IconCheck />,
                color: "teal"
            })
            navigate(-1)
        } catch (error) {
            notifications.show({
                title: "There was an error",
                message: `Error: ${error}`,
                icon: <IconX />,
                color: "red"
            })
        }
    }
    return (<>{
        isLoading ? (<><Skeleton /></>) : (
            <Box style={{ margin: "auto" }} maw={600} mt={25}>
                <Stack gap={25}>
                    <Flex direction={"column"} gap={8}>
                        <IntegrationHeader
                            title={data?.name as string}
                            icon={<>{integrationIconMap[data?.applicationName as string]}</>}
                        />
                        <Text c="dimmed" size="xs">
                            {data?.description}
                        </Text>
                    </Flex>
                    <Flex gap={2} direction={"column"}>
                        <Text c="myColor" fw={600}>
                            Actions
                        </Text>
                        <Text c={"dimmed"} size="xs">
                            The following are actions your integration has:
                        </Text>
                    </Flex>
                    <Grid>
                        {
                            data?.actions.map((action) => {
                                return <Grid.Col span={6}><ActionDisplayCard {...ActionDescriptionMap[action]} /></Grid.Col>
                            })
                        }
                    </Grid>

                    <Flex mt={8}>
                        <div style={{ flexGrow: 1 }} />
                        <Button
                            onClick={open}
                            leftSection={<IoTrash />}
                            color="red">Delete</Button>
                    </Flex>
                </Stack>
                <Modal opened={opened} onClose={close} centered withCloseButton={false}>
                    <Stack gap={25}>
                        <TextInput
                            c="red"
                            label={`Delete ${data?.name}?`}
                            description="This action will permanently delete all instances where this integration is used in agents"
                            placeholder={`Type '${data?.name}'`}
                            key={form.key('deleteConfirmText')}
                            {...form.getInputProps('deleteConfirmText')}
                        />
                        <Flex>
                            <div style={{ flexGrow: 1 }} />
                            <Group align="right">
                                <Button onClick={close} color="gray" variant="subtle" >Cancel</Button>
                                <Button onClick={onConfirm} color="red" leftSection={<IoTrash />}>Confirm</Button>
                            </Group>
                        </Flex>
                    </Stack>
                </Modal>
            </Box>
        )
    }</>)
}

export default IntegrationViewPage