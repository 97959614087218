import React from "react";
import {
  Flex,
  em,
  ColorSwatch,
  Divider,
  Grid,
  Paper,
  Text,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { AgentAggregateType } from "../../types/agent/agent";
import { ImageIconMap } from "../integration/card/IntegrationCard";
import { useNavigate } from "react-router-dom";
import { IconBrain, IconCode, IconFile, IconPencil } from "@tabler/icons-react";

interface IEmptyConversationView {
  agent: AgentAggregateType;
}

const EmptyConversationView: React.FC<IEmptyConversationView> = ({ agent }) => {
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const navigate = useNavigate();
  return (
    <>
      {agent ? (
        <Flex
          style={{
            height: "100%",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            padding: isMobile ? "20px" : "40px",
          }}
          gap={25}
        >
          <Flex gap={8}>
            <ColorSwatch
              style={{ cursor: "pointer" }}
              color={agent.metadata.agentColor}
              onClick={() => navigate(`/agents/${agent._id}`)}
            ></ColorSwatch>
            {agent.integrations.length > 0 ? (
              <>
                <Divider orientation="vertical" />
                <Flex gap={8}>
                  {agent.integrations.map((integration) => {
                    return <>{ImageIconMap[integration.applicationName]}</>;
                  })}
                </Flex>
              </>
            ) : (
              <></>
            )}
          </Flex>
          <h3 className="shimmer" style={{ fontSize: isMobile ? 24 : 36 }}>
            What can {agent.name} help with?
          </h3>
          <Grid style={{ width: "90%" }}>
            <Grid.Col span={6}>
              <Paper
                withBorder
                style={{ width: "100%", height: "100%" }}
                p={18}
              >
                <IconPencil
                  style={{ color: "3a4bdd", width: 24, height: 24 }}
                />
                <Text fw={600} c="myColor" mt={6}>
                  Summarise
                </Text>
                <Text size="sm" c="dimmed">
                  Summarise this report for me & explain it for a 5 year old
                </Text>
              </Paper>
            </Grid.Col>
            <Grid.Col span={6}>
              <Paper
                withBorder
                style={{ width: "100%", height: "100%" }}
                p={18}
              >
                <IconFile style={{ color: "3a4bdd", width: 24, height: 24 }} />
                <Text fw={600} c="myColor" mt={6}>
                  Analyse Document
                </Text>
                <Text size="sm" c="dimmed">
                  Analyse this research paper & summarise it in point form
                </Text>
              </Paper>
            </Grid.Col>
            <Grid.Col span={6}>
              <Paper
                withBorder
                style={{ width: "100%", height: "100%" }}
                p={18}
              >
                <IconBrain style={{ color: "3a4bdd", width: 24, height: 24 }} />
                <Text fw={600} c="myColor" mt={6}>
                  Brainstorm
                </Text>
                <Text size="sm" c="dimmed">
                  Brainstorm 10 start up ideas for me to work on over the
                  holidays
                </Text>
              </Paper>
            </Grid.Col>
            <Grid.Col span={6}>
              <Paper
                withBorder
                style={{ width: "100%", height: "100%" }}
                p={18}
              >
                <IconCode style={{ color: "3a4bdd", width: 24, height: 24 }} />
                <Text fw={600} c="myColor" mt={6}>
                  Coding
                </Text>
                <Text size="sm" c="dimmed">
                  Help me write a set of API endpoints for a book keeping app
                </Text>
              </Paper>
            </Grid.Col>
          </Grid>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
};

export default EmptyConversationView;
