import { ActionIcon, Box, Button, Flex, Group, Paper, Skeleton, Stack, Switch, Text } from "@mantine/core"
import IntegrationHeader from "../../../../../components/integration/header/IntegrationPermissionsList"
import { IoAdd, IoCalculator, IoCalendar, IoHammer } from "react-icons/io5"
import { useQuery } from "@tanstack/react-query"
import { useAuth0 } from "@auth0/auth0-react"
import { createIntegration, getAllIntegrations } from "../../../../../handler/integration/integration"
import { useForm } from "@mantine/form"
import { useEffect, useState } from "react"
import { CreateIntegrationType, IntegrationType } from "../../../../../types/integration/integration"
import { ApplicationActionType } from "../../../../../types/integration/action/action"
import { useNavigate } from "react-router-dom"
import { notifications } from "@mantine/notifications"
import { IconCheck, IconCross } from "@tabler/icons-react"


// Note: The default permissions are seeded into the MongoDB database. 
// Ensure that the created_by ID for these integrations matches the user ID of the creator.


const ONWORD_CALCULATOR: CreateIntegrationType = {
    name: "Calculator",
    permission_id: "66f5ff29d98d67a3b8f20a74",
    description: "Completes mathematical calculations",
    config: { "id": "" },
    tools: [{
        name: "Calculator",
        description: "A tool for performing mathematical calculations.",
        action: "ONWORD_CALCULATOR"
    }]
};

const ONWORD_DATE_COUNTER: CreateIntegrationType = {
    name: "Date Counter",
    description: "Counts dates accurately",
    permission_id: "66f600a3ac95b386a6188dac",
    config: { "id": "" },
    tools: [{
        name: "DateCounter",
        description: "A tool for counting dates and managing schedules.",
        action: "ONWORD_DATETIME"
    }]
};

const DEFAULT_INTEGRATIONS = {
    ONWORD_CALCULATOR, ONWORD_DATE_COUNTER
}


const OnwordDefaultToolsPage = () => {
    const { getAccessTokenSilently } = useAuth0()
    const [existingIntegrations, setExistingIntegrations] = useState<IntegrationType[]>()
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const form = useForm({
        initialValues: {
            calculator: false,
            dateCounter: false
        }
    })
    const { data, isLoading, isError } = useQuery({
        queryKey: [],
        queryFn: async () => {
            const token = await getAccessTokenSilently()
            const integrationsResponse = await getAllIntegrations(token)
            setExistingIntegrations(integrationsResponse)
            if (integrationsResponse.some(integration => integration.applicationName === "ONWORD_CALCULATOR")) {
                form.setFieldValue("calculator", true);
            }
            if (integrationsResponse.some(integration => integration.applicationName === "ONWORD_DATE_COUNTER")) {
                form.setFieldValue("dateCounter", true);
            }
            return integrationsResponse
        }
    })
    const defaultIntegrationExists = (applicationName: string): boolean => {
        const integration = existingIntegrations?.find(integration => integration.applicationName === applicationName);
        return integration ? true : false;
    }
    const addDefaultTool = async (applicationName: "ONWORD_CALCULATOR" | "ONWORD_DATE_COUNTER") => {
        if (applicationName in DEFAULT_INTEGRATIONS) {
            try {
                const token = await getAccessTokenSilently()
                await createIntegration(token, DEFAULT_INTEGRATIONS[applicationName] as CreateIntegrationType)
                notifications.show({
                    title: <Text c="myColor">{`Successfully created integration`}</Text>,
                    message: `Your integration has been created and is ready for use`,
                    autoClose: 3000,
                    color: "teal",
                    icon: <IconCheck />
                })

                navigate("/integrations")
            } catch (error) {
                // TODO: ADD NOTIFICATION
                notifications.show({
                    title: <Text c="myColor">{`Failed to create default integration`}</Text>,
                    message: "There was an error with creating defaule integration",
                    autoClose: 3000,
                    color: "red",
                    icon: <IconCross />
                })
                console.error(error)
            }
        } else {
            throw new Error("This default integration does not exist")
        }
    }
    return (
        <Box maw={600} m={"auto"} mt={25}>
            <IntegrationHeader
                w={400}
                icon={""}
                title="Onword Integrations"
            />
            <br></br>
            <Text c="myColor" size="sm" fw={500}>Enable Default Integrations</Text>
            <Text c={"dimmed"} size="xs">Onword.ai provides you with default integrations to improve your agent's math & date counting skills, enable them to get started</Text>
            <Stack gap={25} mt={25}>
                <Skeleton visible={isLoading}>
                    <Paper withBorder mih={60} style={{ display: "flex" }} p={10}>
                        <Group align="flex-start" wrap="nowrap" style={{ width: "100%" }}>
                            <IoCalculator style={{ margin: "auto", minWidth: 28, minHeight: 28, color: "lightgray" }} />

                            <div style={{ marginBlock: "auto", width: "100%" }}>
                                <Text fw={500} c="myColor" size="sm">Calculator</Text>
                                <Text c="dimmed" size="xs">Allows agents to calculate math problems accurately</Text>
                            </div>

                            {/* <div style={{ width: "100%" }}></div> */}
                            {/* <Switch disabled={isError} color="myColor" style={{ margin: "auto" }} defaultChecked={form.values.calculator !== undefined} /> */}
                            <ActionIcon
                                color="myColor" style={{ margin: "auto" }}
                                disabled={defaultIntegrationExists("ONWORD_CALCULATOR")}
                                onClick={async () => await addDefaultTool("ONWORD_CALCULATOR")}
                            >
                                <IoAdd />
                            </ActionIcon>
                        </Group>
                    </Paper>
                </Skeleton>
                <Skeleton visible={isLoading}>
                    <Paper withBorder mih={60} style={{ display: "flex" }} p={10}>
                        <Group align="flex-start" wrap="nowrap" style={{ width: "100%" }}>
                            <IoCalendar style={{ margin: "auto", minWidth: 28, minHeight: 28, color: "lightgray" }} />

                            <div style={{ marginBlock: "auto", width: "100%" }}>
                                <Text fw={500} c="myColor" size="sm">Date Counter</Text>
                                <Text c="dimmed" size="xs">Allows agents to count dates accurately</Text>
                            </div>

                            {/* <div style={{ width: "100%" }}></div> */}
                            {/* <Switch disabled={isError} color="myColor" style={{ margin: "auto" }} defaultChecked={form.values.dateCounter !== undefined} /> */}
                            <ActionIcon color="myColor" style={{ margin: "auto" }}
                                disabled={defaultIntegrationExists("ONWORD_DATE_COUNTER")}
                                onClick={async () => await addDefaultTool("ONWORD_DATE_COUNTER")}
                            ><IoAdd /></ActionIcon>
                        </Group>
                    </Paper>

                </Skeleton>
                {/* <Flex direction={"row"}>
                    <div style={{ width: "100%" }}></div>
                    <Button miw={100} color="myColor" loading={loading}>Confirm</Button>
                </Flex> */}
            </Stack>
        </Box >)
}

export default OnwordDefaultToolsPage