import { Title, Text, Box, Grid, Paper } from "@mantine/core";
import React from "react";
import GoogleCalendarImage from "../../icons/GoogleCalendarImage";
import GoogleGmailImage from "../../icons/GoogleGmailImage";
import NotionImage from "../../icons/NotionImage";
import AddApplicationCard from "../card/AddApplicationCard";
import { IoDocument, IoDocumentsOutline } from "react-icons/io5";

const suggestedApps = [
  {
    name: "",
    route: "",
    description: "",
    applicationIconUrl: "",
  },
];

const ApplicationSection: React.FC = () => {
  return (
    <Box>
      {/* <Title order={6} style={{ color: '#3a4bdd' }}>Add a New Application</Title> */}
      <Text size="xs" color="dimmed">
        Discover applications to integrate with your agents
      </Text>
      <Grid mt={8} gutter={8}>
        <AddApplicationCard
          title="Add Your Calendar"
          description="Add Your Google Calendar"
          route="/google_calendar"
          icon={<GoogleCalendarImage />}
        />
        <AddApplicationCard
          title="Add Your Email"
          description="Add Your Gmail Threads & Send Emails"
          route="/google_gmail"
          icon={<GoogleGmailImage />}
        />
        <AddApplicationCard
          title="Add Notion"
          description="Add Your Notion Pages"
          route="/notion"
          icon={<NotionImage />}
        />
        <AddApplicationCard
          title="Add Files"
          description="Add Your PDF, DOCX, XLS files, etc."
          route="/notion"
          icon={<IoDocument style={{ fontSize: 28, color: "lightgray" }} />}
        />
      </Grid>
    </Box>
  );
};

export default ApplicationSection;
