import { useLocation } from "react-router-dom"
import CreateAgentForm from "./CreateAgentForm"

export const models = [
    {
        label: "GPT 4o Mini",
        value: JSON.stringify({ model: "gpt-4o-mini", company: "openai", description: "Fastest, Cheapest" })
    },
    {
        label: "GPT 4o",
        value: JSON.stringify({ model: "gpt-4o", company: "openai", description: "Higher Output Quality" })
    },
    {
        disabled: true,
        label: "Claude 3.5 Sonnet (Coming Soon)",
        value: JSON.stringify({ model: "gpt-4o", company: "anthropic", description: "Great at Coding, Reasoning" })
    }
]

const CreateAgentPage = () => {
    const location = useLocation()
    return (
        <>
            <CreateAgentForm models={models}
                defaultIntegrations={location.state ? [location.state] : []}
            />
        </>
    )
}
export default CreateAgentPage