import React from "react"
import { Image } from "@mantine/core"
import GoogleCalendar from "../../assets/google_calendar.png"

export interface IAddApplicationCard {
    width?: number;
    height?: number;
    style?: React.CSSProperties;
}

const GoogleCalendarImage: React.FC<IAddApplicationCard> = ({ width = 28, height = 28, style }) => {
    return (<>
        <Image src={GoogleCalendar} w={width} h={height} style={style} />
    </>)
}
export default GoogleCalendarImage