import { Code, Flex, Indicator, Paper, Text } from "@mantine/core"
import React from "react"
import { IoCloseOutline, IoDocumentSharp, IoImage } from "react-icons/io5"

export interface IFilePill {
    index: number
    filename: string
    fileSize: string
    // TODO: make the following fields mandatory
    contentType: "image" | "document"
    path?: string
    text?: string
    onRemove: (index: number) => void
}

const FilePill: React.FC<IFilePill> = ({ index, filename, fileSize, contentType, onRemove }) => {
    return (
        <Indicator
            size={20}
            color="myColor"
            label={<IoCloseOutline
                style={{ margin: "-2px" }} />}
            onClick={() => onRemove(index)}
        >
            <Paper mah={56} maw={100} shadow="xs" p={4} px={8} withBorder>
                <Flex direction={"row"} gap={8}>
                    {contentType === "document" ? (<IoDocumentSharp color="myColor" style={{ margin: "auto" }} />) : (<></>)}
                    {contentType === "image" ? (<IoImage color="myColor" style={{ margin: "auto" }} />) : (<></>)}
                    <Flex direction={"column"}>
                        <Text size="sm" w={64} truncate><Code>{filename}</Code></Text>
                        <Text size="xs" truncate c="dimmed">{fileSize} MB</Text>
                    </Flex>
                </Flex>
            </Paper>
        </Indicator>
    )
}
export default FilePill