import { useAuth0 } from "@auth0/auth0-react";
import GoogleCalendarIntegrationForm from "./GoogleCalendarIntegrationForm";

import { useQuery } from "@tanstack/react-query"
import { getAppPermissions } from "../../../../../../handler/permission/permissionHandler";
import { PermissionType } from "../../../../../../types/permission/permission";
import { useState } from "react";

const GoogleCalendarIntegrationPage = () => {
    const APP_UNIQUE_NAME = "GOOGLE_CALENDAR"
    const { getAccessTokenSilently } = useAuth0()
    const [permissions, setPermissions] = useState<PermissionType[]>([])
    // gets the existing permissions for the app
    useQuery({
        queryKey: [APP_UNIQUE_NAME],
        queryFn: async () => {
            const token = await getAccessTokenSilently()
            const permissionsResponse = await getAppPermissions(APP_UNIQUE_NAME, token)
            setPermissions(permissionsResponse)
            return permissionsResponse
        }
    })


    return (
        <>

            <GoogleCalendarIntegrationForm
                permissions={permissions}
                actions={[{
                    name: "Create Calendar Event",
                    description: "Create Google Calendar events",
                    action: "GOOGLE_CALENDAR_EVENT_CREATE"
                },
                {
                    name: "Search Calendar Events",
                    description: "Searches events in your Google Calendar",
                    action: "GOOGLE_CALENDAR_EVENT_SEARCH"
                },
                {
                    name: "Update Calendar Events",
                    description: "Updates the details of your Google Calendar",
                    action: "GOOGLE_CALENDAR_EVENT_UPDATE"
                },
                {
                    name: "Delete Calendar Events",
                    description: "Deletes your Google Calendar event",
                    action: "GOOGLE_CALENDAR_EVENT_DELETE"
                }]}
            />
        </>
    )
}

export default GoogleCalendarIntegrationPage;