import { FileWithPath } from "@mantine/dropzone";
import axios from "axios";
import { MONOLITH_ROUTE } from "../routes";

export const uploadFiles = async (token: string, files: File[] | FileWithPath[]): Promise<Record<string, any>[]> => {
    const promises = files.map(async (file) => {
        const formData = new FormData();
        const fileBytes = await file.arrayBuffer()
        const blob = new Blob([fileBytes], { type: file.type });
        formData.append('file', blob, file.name);
        const response = await axios.post(

            `${MONOLITH_ROUTE}/chat/upload`, // Adjust the URL as necessary
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        return response.data as Record<string, any>;
    });

    return Promise.all(promises);
};
