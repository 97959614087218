import { ActionIcon, Flex, Title } from "@mantine/core"
import GoogleCalendarImage from "../../icons/GoogleCalendarImage"
import React from "react"
import { IoArrowBack } from "react-icons/io5"
import { useNavigate } from "react-router-dom"


interface IIntegrationHeader {
    icon: React.ReactNode
    title: React.ReactNode | React.ReactNode[]
    w?: number
}
const IntegrationHeader: React.FC<IIntegrationHeader> = ({ icon, title, w = 300 }) => {
    const navigate = useNavigate()
    return (
        <Flex w={w} gap={10}>
            <ActionIcon variant="subtle" color="myColor"
                onClick={() => navigate(-1)}
            >
                <IoArrowBack style={{ color: "lightgray" }} />
            </ActionIcon>
            {icon}
            <Title
                order={2}
                fz={24}
                style={{ color: "#3a4bdd" }}>
                {title}
            </Title>
        </Flex >)
}
export default IntegrationHeader