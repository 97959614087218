import React, { useEffect, useState } from "react";
import IntegrationsSection from "../../components/integration/section/IntegrationsSection";
import ApplicationSection from "../../components/integration/section/ApplicationSection";
import { Box, Divider, em } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import IntegrationList from "../../components/integration/list/IntegrationListTable";
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { getAllIntegrations } from "../../handler/integration/integration";
import { IntegrationType } from "../../types/integration/integration";

const IntegrationPage: React.FC = () => {
    const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
    const [integrations, setIntegrations] = useState<IntegrationType[]>()
    const [search, setSearch] = useState<string>("")
    const { getAccessTokenSilently } = useAuth0()
    const { data, isLoading, refetch } = useQuery({
        queryKey: [],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const integrationsResponse = await getAllIntegrations(token);
            setIntegrations(integrationsResponse)
            return integrationsResponse;
        },
    });

    const filterIntegrations = (search: string) => {
        if (search === "") {
            return data
        }
        return integrations?.filter(integration =>
            integration.name.toLowerCase().includes(search.toLowerCase())
        );
    };

    useEffect(() => {
        const filteredIntegrations = filterIntegrations(search);
        setIntegrations(filteredIntegrations);
    }, [search]);

    return (
        <Box px={isMobile ? 5 : 20}>
            <ApplicationSection />
            <Divider mt={16} mb={32} />
            <IntegrationsSection
                setSearch={setSearch}
            />
            <IntegrationList
                integrations={integrations as IntegrationType[]}
                isLoading={isLoading}
                refetch={refetch}
            />
        </Box>
    );
};

export default IntegrationPage;
