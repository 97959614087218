import axios from "axios";
import { TOOL_MONOLITH_ROUTE } from "../../routes";
import {
  NotionDatabaseDataType,
  NotionDisplayType,
  NotionEntityType,
  NotionPageDataType,
} from "../../../types/integration/apps/notion/notion";

export const getNotionPagesAndDatabases = async (
  permissionId: string,
  token: string
): Promise<NotionDisplayType[]> => {
  try {
    const response = await axios.get(`${TOOL_MONOLITH_ROUTE}/notion/search`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        permission_id: permissionId,
        query: "",
      },
    });
    console.log(response);
    const results: (NotionDatabaseDataType | NotionPageDataType)[] =
      response.data["results"];
    const resultDisplays: NotionDisplayType[] = [];
    for (let result of results) {
      let thisObj = {
        id: result.id,
        created_time: result.created_time,
        last_edited_time: result.last_edited_time,
        type: result.object,
      };
      if (result.object === "database") {
        if (result.title.length > 0) {
          resultDisplays.push({
            title: result.title[0].text.content,
            ...thisObj,
          });
        } else {
          let currObj: any[] = [];
          if (
            "Title" in result.properties &&
            "title" in result.properties.Title
          ) {
            currObj = result.properties.Title.title;
          } else if (
            "title" in result.properties &&
            "title" in result.properties.title
          ) {
            currObj = result.properties.title.title;
          } else if (
            "Name" in result.properties &&
            "title" in result.properties.Name
          ) {
            currObj = result.properties.Name.title;
          }
          if (currObj.length > 0) {
            resultDisplays.push({ title: currObj[0].plain_text, ...thisObj });
          }
        }
      } else if (result.object === "page") {
        let currObj: any[] = [];
        if (
          "Title" in result.properties &&
          "title" in result.properties.Title
        ) {
          currObj = result.properties.Title.title;
        } else if (
          "title" in result.properties &&
          "title" in result.properties.title
        ) {
          currObj = result.properties.title.title;
        } else if (
          "Name" in result.properties &&
          "title" in result.properties.Name
        ) {
          currObj = result.properties.Name.title;
        }
        if (currObj.length > 0) {
          resultDisplays.push({ title: currObj[0].plain_text, ...thisObj });
        }
      }
    }

    return resultDisplays;
  } catch (error) {
    throw new Error("There was an error with getting Notion Pages");
  }
};
