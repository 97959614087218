import {
  ActionIcon,
  Button,
  ColorSwatch,
  Flex,
  Modal,
  Paper,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import { AgentType } from "../../../types/agent/agent";
import { useEffect, useRef, useState } from "react";
import { useHotkeys } from "@mantine/hooks";
import { IoAdd, IoReturnDownBack } from "react-icons/io5";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import {
  ConversationType,
  createConversation,
} from "../../../handler/conversation/conversation";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { RiRobot2Fill } from "react-icons/ri";

interface ICreateChatModal {
  agents: AgentType[];
  opened: boolean;
  close: () => void;
}

const CreateChatModal: React.FC<ICreateChatModal> = ({
  agents,
  opened,
  close,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [count, setCount] = useState<number>(0);
  const navigate = useNavigate();
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  useEffect(() => {
    if (itemRefs.current[count]) {
      itemRefs.current[count]?.scrollIntoView({
        block: "center",
      });
    }
  }, [count]);

  const increment = () => {
    if (count < agents.length - 1) {
      setCount((prevCount: number) => prevCount + 1);
    }
  };

  const decrement = () => {
    if (count > 0) {
      setCount((prevCount: number) => prevCount - 1);
    }
  };
  useHotkeys([
    ["ArrowUp", () => decrement()],
    ["ArrowDown", () => increment()],
    ["Enter", async () => onCreateConversation(count)],
  ]);

  const onPaperClick = (index: number) => {
    setCount(index);
  };
  const onCreateConversation = async (index: number) => {
    try {
      const selectedAgent: AgentType = agents[index];
      const token = await getAccessTokenSilently();
      const conversation: ConversationType = await createConversation(
        token,
        `New Chat w/ ${selectedAgent.name}`,
        selectedAgent._id
      );
      navigate(`/chats/${conversation._id}`);
      close();
      notifications.show({
        title: "Successfully created chat",
        message: `Created chat with ${selectedAgent.name}`,
        autoClose: 3000,
        color: "teal",
        icon: <IconCheck />,
      });
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      notifications.show({
        title: "Failed to create chat",
        message: `Error: ${errorMessage}`,
        autoClose: 3000,
        color: "red",
        icon: <IconX />,
      });
    }
  };
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title={
          <>
            <Text c="myColor" fw={600}>
              Start a Chat
            </Text>
            <Text c="dimmed" size="xs">
              Select an agent you'd like to start chatting with
            </Text>
          </>
        }
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        {agents.length > 0 ? (
          <ScrollArea h={230} style={{ width: "100%" }}>
            <Stack gap={12}>
              {agents.map((agent: AgentType, index: number) => {
                return (
                  <div ref={(el) => (itemRefs.current[index] = el)}>
                    <Paper
                      onDoubleClick={async () =>
                        await onCreateConversation(index)
                      }
                      onClick={() => onPaperClick(index)}
                      withBorder
                      style={{
                        borderColor: count === index ? "#3a4bdd" : "lightgray",
                        borderWidth: count === index ? 2 : 1,
                      }}
                      p={10}
                    >
                      <Flex gap={10}>
                        <ColorSwatch
                          color={agent.metadata?.agentColor}
                          style={{ marginBlock: "auto" }}
                        />
                        <div style={{ flexGrow: 1 }}>
                          <Text c={"myColor"} fw={500}>
                            {agent.name}
                          </Text>
                          <Text c="dimmed" size="xs">
                            {agent.model}
                          </Text>
                        </div>
                        <ActionIcon
                          display={count === index ? "block" : "none"}
                          color="myColor"
                          variant={"light"}
                          style={{
                            margin: "auto",
                          }}
                        >
                          ⮐
                        </ActionIcon>
                      </Flex>
                    </Paper>
                  </div>
                );
              })}
            </Stack>
          </ScrollArea>
        ) : (
          <Paper
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
            p={25}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "auto",
                gap: 10,
              }}
            >
              <RiRobot2Fill
                color="lightgray"
                style={{ margin: "auto", width: 32, height: 32 }}
              />
              <Text c="dimmed" size="sm">
                You have no agents, click 'Add Agent' to start
              </Text>
              <Button
                size="sm"
                variant="light"
                color="myColor"
                rightSection={<IoAdd />}
                onClick={() => {
                  navigate("/agents/create");
                  close();
                }}
              >
                Add Agent
              </Button>
            </div>
          </Paper>
        )}
      </Modal>
    </>
  );
};

export default CreateChatModal;
