import { Avatar, Group, Radio, Text } from "@mantine/core"
import React from "react"

interface IGenericRadioCard {
    avatar: React.ReactNode
    title: React.ReactNode
    subtext: string
    checked: boolean
    onChecked: () => void
}

const GenericRadioCard: React.FC<IGenericRadioCard> = ({ avatar, title, subtext, checked, onChecked }) => {
    return (<Radio.Card p={4} px={8} >
        <Group wrap="nowrap" align="flex-start" gap={6}>
            {avatar}
            <div style={{ width: "100%" }}>

                {title}
                <Text size="xs" c="dimmed">{subtext}</Text>
            </div>
            <Radio.Indicator m={10} color="myColor"
                checked={checked}
                onClick={onChecked}
            />
        </Group>
    </Radio.Card>)
}
export default GenericRadioCard