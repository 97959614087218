import { useParams } from "react-router-dom";
import { ActionIcon, em, Flex, Paper, Text } from "@mantine/core";
import { useDisclosure, useHover, useLocalStorage, useMediaQuery } from "@mantine/hooks";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTypingEffect } from "../chat/box/ChatMessageBox";
import { IoEllipsisVertical } from "react-icons/io5";
import FrameConversationDropdownMenu from "./menu/FrameConversationDropdownMenu";
import { ConversationViewType } from "../../handler/conversation/conversation";

interface IFrameConversationCard {
  conversation: ConversationViewType;
  isTyping: boolean;
  trigger: () => void;
}

const FrameConversationCard: React.FC<IFrameConversationCard> = ({
  conversation,
  trigger,
  isTyping,
}) => {
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const { hovered, ref } = useHover();
  const [_, setValue] = useLocalStorage({
    key: `conversation_title_creation`,
  });

  const { id: conversationId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const bold = hovered ? 700 : 500;
  const color = hovered ? "myColor" : "dimmed";
  const typed = useTypingEffect(conversation.name, 20, true, () =>
    setValue("")
  );

  return (
    <Paper py={8} style={{ cursor: "pointer", width: isMobile ? "100%" : 170 }}>
      <Flex ref={ref}>
        <div
          style={{ width: "100%" }}
          onClick={() => navigate(`/chats/${conversation._id}`)}
        >
          {conversationId !== conversation._id ? (
            <Text truncate size="sm" fw={bold} c={color}>
              {conversation.name}
            </Text>
          ) : isTyping ? (
            <p
              className="shimmer"
              style={{
                fontWeight: 600,
                marginBlock: -8,
                fontSize: "medium",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {typed}
            </p>
          ) : (
            <Text truncate size="sm" fw={700} c={"myColor"}>
              {typed}
            </Text>
          )}
        </div>
        <FrameConversationDropdownMenu
          trigger={trigger}
          hoverRef={ref}
          conversation={conversation}
        >
          <ActionIcon
            style={{ opacity: hovered ? 100 : 0 }}
            size={"sm"}
            color="myColor"
            variant="light"
          >
            <IoEllipsisVertical />
          </ActionIcon>
        </FrameConversationDropdownMenu>
      </Flex>
    </Paper>
  );
};

export default FrameConversationCard;
