import { Title, Text } from "@mantine/core"


interface IGenericIntegrationTitle {
    title: string
    subtext: string
}
const GenericIntegrationTitle: React.FC<IGenericIntegrationTitle> = ({ title, subtext }) => {
    return (<>
        <Title order={4} fw={500}
            style={{ margin: "auto" }} c="myColor">
            {title}
        </Title>
        <Text size="xs" c="dimmed" >
            {subtext}
        </Text>
    </>)
}

export default GenericIntegrationTitle