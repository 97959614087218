import {
  ActionIcon,
  Box,
  Code,
  ColorSwatch,
  Flex,
  Grid,
  Paper,
  Text,
  Tooltip,
} from "@mantine/core";
import { IoChatbubbleEllipses, IoEllipsisVertical } from "react-icons/io5";
import { AgentType } from "../../types/agent/agent";
import AgentDropdownMenu from "./menu/AgentDropdownMenu";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

interface IAgentCard {
  agent: AgentType;
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<AgentType[], Error>>;
}

const AgentCard: React.FC<IAgentCard> = ({ agent, refetch }) => {
  const navigate = useNavigate();
  
  return (
    <Grid.Col span={3}>
      <Paper style={{ width: "100%", cursor: "pointer" }} withBorder p={8}>
        <Flex gap={8} style={{ margin: "auto", width: "100%" }}>
          <ColorSwatch
            withShadow
            color={agent.metadata.agentColor}
            style={{ marginBlock: "auto" }}
          />
          <div
            style={{ flexGrow: 1 }}
            onClick={() => navigate(`/agents/${agent._id}`)}
          >
            <Text c={"myColor"} fw={500} size="sm" style={{ width: "100%" }}>
              {agent.name}
            </Text>
            <Box>
              <Code>{agent.model}</Code>
            </Box>
          </div>
          <div style={{ marginBlock: "auto" }}>
            <AgentDropdownMenu agent={agent} refetch={refetch}>
              <ActionIcon color="myColor" variant="subtle">
                <IoEllipsisVertical />
              </ActionIcon>
            </AgentDropdownMenu>
          </div>
        </Flex>
      </Paper>
    </Grid.Col>
  );
};
export default AgentCard;
