
import { useAuth0 } from '@auth0/auth0-react';
import { Menu, rem } from '@mantine/core';
import {
    IconSettings,
    IconCreditCard,
    IconGraph,
    IconLogout,
} from '@tabler/icons-react';

interface IUserFrameDropdown {
    children: React.ReactNode | React.ReactNode[]
}
const UserFrameDropdown: React.FC<IUserFrameDropdown> = ({ children }) => {
    const { logout } = useAuth0();
    return (
        <Menu shadow="md" width={200} >
            <Menu.Target>
                {children}
            </Menu.Target>

            <Menu.Dropdown>
                {/* <Menu.Label>Manage Account</Menu.Label>
                <Menu.Item leftSection={<IconSettings style={{ width: rem(14), height: rem(14) }} />}>
                    Settings
                </Menu.Item> */}
                {/* <Menu.Item leftSection={<IconCreditCard style={{ width: rem(14), height: rem(14) }} />}>
                    Billing
                </Menu.Item> */}
                {/* <Menu.Item leftSection={<IconGraph style={{ width: rem(14), height: rem(14) }} />}>
                    Usage
                </Menu.Item> */}
                {/* <Menu.Divider />

                <Menu.Label>Danger zone</Menu.Label> */}
                <Menu.Item
                    onClick={() => logout({ logoutParams: { returnTo: `${window.location.origin}/login` } })}
                    color="red"
                    leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />}
                >
                    Log Out
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );

}

export default UserFrameDropdown