import { Flex, Group } from "@mantine/core"
import FilePill, { IFilePill } from "./FilePill"
import { FileMessageType } from "../../../pages/chat/ChatPage"


interface IFilePillList {
    files: FileMessageType[]
    setFiles: React.Dispatch<React.SetStateAction<FileMessageType[]>>
}

const FilePillList: React.FC<IFilePillList> = ({ files, setFiles }) => {
    const removeFile = (index: number) => {
        setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    }
    return (
        <Flex w="100%" gap={25} wrap="wrap">
            {files.map((file, index) => {
                return <FilePill index={index} filename={file.srcFilename} fileSize={(file.size / (1024 * 1024)).toFixed(2)} onRemove={removeFile} contentType={file.contentType} />
            })}
        </Flex>)
}
export default FilePillList