import { AgentPost } from "../../types/agent/agent";
import { validateActionSet, validateStringEmpty, validateStringHasSpace } from "../base";

export const validateAgentPost = (agentPost: AgentPost): void => {
    validateStringEmpty("name", agentPost.name);
    validateStringEmpty("systemPrompt", agentPost.systemPrompt);
    validateActionSet(agentPost.integrations, ["integration1", "integration2", "integration3"]); // Replace with actual accepted integrations

    if (agentPost.temperature < 0 || agentPost.temperature > 1) {
        throw new Error("Temperature must be between 0 and 1");
    }
    if (agentPost.maxIterations <= 0) {
        throw new Error("Max iterations must be greater than 0");
    }
    validateStringHasSpace("model", agentPost.model);
}

