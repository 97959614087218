import axios from "axios";
import { MessageType } from "../message/message";
import { MONOLITH_ROUTE } from "../routes";

export type ConversationType = {
  _id: string;
  name: string;
  createdBy: string;
  lastUpdated: string;
  createdOn: string;
  agentId: string;
  tokens: number;
  messages: MessageType[];
};

export type ConversationViewType = {
  _id: string;
  name: string;
  createdBy: string;
  lastUpdated: string;
  createdOn: string;
  agentId: string;
  tokens: number;
};

export const getConversation = async (
  token: string,
  conversationId: string
): Promise<ConversationType> => {
  try {
    const response = await axios.get(
      `${MONOLITH_ROUTE}/conversations/${conversationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data as ConversationType;
  } catch (error) {
    console.error(error);
    throw new Error("There was an error with getting conversation");
  }
};

export const getConversations = async (
  token: string
): Promise<ConversationViewType[]> => {
  try {
    const response = await axios.get(`${MONOLITH_ROUTE}/conversations`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data as ConversationViewType[];
  } catch (error) {
    console.error(error);
    throw new Error("There was an error with getting all conversations");
  }
};

export const createConversation = async (
  token: string,
  name: string,
  agentId: string
): Promise<ConversationType> => {
  try {
    const response = await axios.post(
      `${MONOLITH_ROUTE}/conversations/create`,
      {
        name,
        agentId,
        // TODO: fix this in the backend
        createdBy: "",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data as ConversationType;
  } catch (error) {
    throw new Error("There was an error with creating conversation");
  }
};
export const deleteConversation = async (
  token: string,
  conversationId: string
): Promise<void> => {
  try {
    const response = await axios.delete(`${MONOLITH_ROUTE}/conversations/${conversationId}/delete`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 404) {
      throw new Error("Document was not found");
    }
  } catch (error) {
    console.error(error);
    throw new Error("There was an error with deleting the conversation");
  }
};

export const renameConversation = async (
  token: string,
  conversationId: string,
  newName: string
): Promise<void> => {
  try {
    const response = await axios.put(
      `${MONOLITH_ROUTE}/conversations/${conversationId}/rename`,
      {
        name: newName,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 404) {
      throw new Error("Conversation not found");
    }
  } catch (error) {
    console.error(error);
    throw new Error("There was an error with renaming the conversation");
  }
};
