
import { Paper, Flex, Text } from "@mantine/core"
import { IoPersonAdd } from "react-icons/io5"

interface INoPermissionDisplay {
    text: string,
    icon: React.ReactNode
    mih?: number
}

const NoPermissionsDisplay: React.FC<INoPermissionDisplay> = ({ text, icon, mih = 100 }) => {
    return (<Paper withBorder my={10} mih={mih} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        {/*
if no permissions
show a button to create a new permission
*/}
        <Flex w={400} direction={"column"} align={"center"} gap={10} p={20} >
            {icon}
            <Text size="xs" c="dimmed" style={{ textAlign: "center" }}>
                {text}
            </Text>
        </Flex>
    </Paper>)
}
export default NoPermissionsDisplay