import { useQuery } from "@tanstack/react-query";
import EditAgentForm from "./EditAgentForm"
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getAgent } from "../../handler/agent/agent";
import { AgentAggregateType } from "../../types/agent/agent";

const EditAgentPage = () => {
    const { id } = useParams<{ id: string }>();
    const { getAccessTokenSilently } = useAuth0()
    const { data, isError, isLoading, refetch } = useQuery({
        queryKey: [id],
        queryFn: async () => {
            const token = await getAccessTokenSilently()
            const integrationsResponse = await getAgent(token, id as string)
            return integrationsResponse
        }
    })
    return (<>
        {(!isLoading && data !== undefined) ? <EditAgentForm agent={data} refetch={refetch} /> : <>Loading...</>}
    </>)
}

export default EditAgentPage