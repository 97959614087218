import React from 'react';
import { Button } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { MD5 } from 'crypto-js';
import { useLocalStorage } from '@mantine/hooks';

interface IOAuthRedirectButton {
    route: string
}
const hashSub = (subString: string): string => {
    return MD5(subString).toString().substring(0, 24);
};

const OAuthRedirectButton: React.FC<IOAuthRedirectButton> = ({ route }) => {

    const OAUTH_ROUTE = process.env.REACT_APP_OAUTH_ROUTE ? process.env.REACT_APP_OAUTH_ROUTE : "http://localhost:8088"
    const { user } = useAuth0();
    const currentPath = window.location.pathname;
    const [_, setValue] = useLocalStorage({
        key: 'onwordExistingRedirect',
    });

    const handleRedirect = async () => {
        try {
            setValue(currentPath)
            window.location.href = `${OAUTH_ROUTE}${route}?uid=${hashSub(user?.sub as string)}`
        } catch (error) {
            throw new Error(`Error during authenticated redirect: ${error}`);
        }
    };

    return (
        <Button onClick={handleRedirect} color="myColor">
            Add Account
        </Button>
    );
};

export default OAuthRedirectButton;

