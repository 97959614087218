import React from "react";
import { Box, Flex, Grid, Paper, Text } from "@mantine/core";

interface IAddApplicationCard {
    title: string
    icon: React.ReactNode
    description: string
    route: string
    onClick?: () => void
}

const AddApplicationCard: React.FC<IAddApplicationCard> = ({ title, icon, description, route, onClick }) => {
    return (
        <Grid.Col span={3} color="blue" style={{ cursor: "pointer" }} onClick={onClick}>
            <Paper w={"100%"} h={42} color="red" withBorder style={{ overflow: "hidden" }}>
                <Flex direction={"row"} gap={2}>
                    <Box m={6}>{icon}</Box>
                    <Flex direction={"column"} mt={4}>
                        <Text size="xs" fw={500} color="myColor">{title}</Text>
                        <Text size="xs" c="dimmed" truncate style={{ width: "100%" }}>{description}</Text>
                    </Flex>
                </Flex>
            </Paper>
        </Grid.Col>
    );
};

export default AddApplicationCard;
