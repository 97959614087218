import GoogleGmailIntegrationForm from "./GoogleGmailIntegrationForm";

const GoogleGmailIntegrationPage = () => {
    return (
        <div>
            <GoogleGmailIntegrationForm actions={[
                {
                    name: "Create Email Draft",
                    description: "Creates an email draft based on your description",
                    action: "GOOGLE_GMAIL_DRAFT_CREATE"
                },
                {
                    name: "Send Email",
                    description: "Sends an email directly to your recepient",
                    action: "GOOGLE_GMAIL_EMAIL_SEND"
                },
                {
                    name: "Search Email Threads",
                    description: "Searches email threads based on your description",
                    action: "GOOGLE_GMAIL_THREAD_SEARCH"
                },
                {
                    name: "Read Email Thread",
                    description: "Reads specific email thread",
                    action: "GOOGLE_GMAIL_THREAD_READ"
                },
            ]} />
        </div>
    )
}

export default GoogleGmailIntegrationPage;