import { Flex, Title, Text } from "@mantine/core"
import OAuthRedirectButton from "../../button/OAuthRedirectButton"


interface IAddAccountHeader {
    title: string
    subtext: string
    oauthRoute: string
}
const AddAccountHeader: React.FC<IAddAccountHeader> = ({ title, subtext, oauthRoute }) => {
    return (
        <Flex justify="space-between" align="center">
            <div>
                <Title order={4} style={{ color: "#3a4bdd" }} fw={500} >
                    {title}
                </Title>
                <Text size="xs" c="dimmed">
                    {subtext}
                </Text>
            </div>
            <OAuthRedirectButton route={oauthRoute} />
        </Flex>
    )
}
export default AddAccountHeader