import { IoCheckmark, IoCheckmarkCircle, IoClose, IoDocument } from "react-icons/io5"
import IntegrationHeader from "../../../../../components/integration/header/IntegrationPermissionsList"
import { Alert, Box, Button, Code, Flex, Group, Loader, Paper, rem, Stack, Text } from "@mantine/core"
import GenericIntegrationTitle from "../../../../../components/title/GenericIntegrationTitle"
import NameDescriptionInput from "../../../../../components/integration/input/NameDescriptionInput"
import { useForm } from "@mantine/form"
import { Dropzone, FileWithPath } from "@mantine/dropzone"
import { IconUpload, IconPhoto, IconX, IconCheck } from '@tabler/icons-react';
import { useEffect, useState } from "react"
import { KnowledgeBaseDocumentResponseType } from "../../../../../types/integration/apps/onword/document"
import { uploadFile } from "../../../../../handler/apps/onword/documentHandler"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import { getKnowledgeBase, KnowledgeBaseDataType } from "../../../../../handler/knowledgeBase/knowledgeBaseHandler"
import { CreateIntegrationType, IntegrationType } from "../../../../../types/integration/integration"
import { formatActions } from "../../../../../helper/integration/tool"
import { createIntegration } from "../../../../../handler/integration/integration"
import { useNavigate } from "react-router-dom"
import { notifications } from "@mantine/notifications"


type DocumentSearchIntegrationSubmissionBody = {
    name: string
    description: string
}
const OnwordDocsIntegrationForm = () => {
    const { getAccessTokenSilently } = useAuth0()
    const [kbLoading, setKbLoading] = useState<boolean>(false)
    const [knowledgeBaseResponse, setKnowledgeBaseResponse] = useState<KnowledgeBaseDocumentResponseType>()
    const [knowledgeBaseId, setKnowledgeBaseId] = useState<string>()
    const [currKnowledgeBase, setCurrKnowledgeBase] = useState<KnowledgeBaseDataType>()
    const navigate = useNavigate()
    const form = useForm(
        {

            initialValues: {
                name: "",
                description: "",
                // permission: permissions ? permissions[0] : undefined,
                // calendar: calendars ? calendars[0] : undefined,
                // actions: [...listedActions]

            }
        }
    )
    const onFileUpload = async (file: FileWithPath) => {
        try {
            console.log(file)
            setKbLoading(true)
            const token = await getAccessTokenSilently()
            const arrayBuffer: ArrayBuffer = await file.arrayBuffer()
            const response: KnowledgeBaseDocumentResponseType = await uploadFile(token, arrayBuffer, file.name, file.type)
            setKnowledgeBaseId(response.knowledgeBaseId)
            setKnowledgeBaseResponse(response)
            setKbLoading(false)
        } catch (error) {
            console.error(error)
            setKbLoading(false)
        }
    }
    const { refetch } = useQuery({
        queryKey: [knowledgeBaseId],
        queryFn: async () => {
            if (knowledgeBaseId) {
                const token = await getAccessTokenSilently()
                const knowledgeBase = await getKnowledgeBase(knowledgeBaseId as string, token)
                setCurrKnowledgeBase(knowledgeBase)
                return knowledgeBase
            }
            return false
        }
    })
    useEffect(() => {
        const intervalKnowledgeBase = setInterval(() => {
            refetch()
        }, 1000)

        // Clear interval if currKnowledgeBase.status is ERROR or COMPLETED
        if (currKnowledgeBase?.status === "ERROR" || currKnowledgeBase?.status === "COMPLETED") {
            clearInterval(intervalKnowledgeBase);
        }

        return () => clearInterval(intervalKnowledgeBase)
    }, [knowledgeBaseResponse, currKnowledgeBase]) // Added currKnowledgeBase to dependencies

    const submitIntegration = async (values: DocumentSearchIntegrationSubmissionBody): Promise<IntegrationType | undefined> => {
        try {
            const formattedTools = formatActions(values.name, values.description, [{
                name: "SearchDocument",
                description: "Searches document for relevant information",
                action: "ONWORD_DOCUMENT_SEARCH"
            }])
            const documentSearchBody: CreateIntegrationType = {
                name: values.name,
                description: values.description,
                permission_id: "5f47a7b6e7179a6ca4f8c847",
                tools: formattedTools,
                config: {
                    knowledge_base_id: knowledgeBaseId
                }
            }
            const token = await getAccessTokenSilently()
            const response = await createIntegration(token, documentSearchBody)
            notifications.show({
                title: <Text c="myColor">{`Successfully created ${values.name}`}</Text>,
                message: `Your ${values.name} has been created and is ready for use`,
                autoClose: 3000,
                color: "teal",
                icon: <IconCheck />
            })
            return response as IntegrationType

        } catch (error) {
            notifications.show({
                title: <Text c="myColor">{`Failed to create ${form.values.name}`}</Text>,
                message: `Your ${form.values.name} has not been integrated`,
                autoClose: 3000,
                color: "red",
                icon: <IconX />
            })
        }
    }
    const submitIntegrationRedirectToAgent = async (values: DocumentSearchIntegrationSubmissionBody) => {
        const response = await submitIntegration(values)
        navigate("/agents/create", { state: response })
    }
    return (
        <>
            <IntegrationHeader
                icon={""}
                title="Your Documents"
            />
            <form>
                <br></br>
                <Stack gap={25}>
                    <div>
                        <GenericIntegrationTitle
                            title={"1. Name & Describe Documents"}
                            subtext={"Name & describe documents for your agents"}
                        />
                        <NameDescriptionInput
                            form={form}
                            textFormKey="name"
                            textAreaFormKey="description"
                            textLabel="Name"
                            textPlaceholder="Example: Class Notes"
                            textAreaLabel="Description"
                            textAreaPlaceholder="Example: For asking information in class notes"
                        />
                    </div>
                    <div>
                        <GenericIntegrationTitle
                            title="2. Add Your Document"
                            subtext="Add documents you want your agents to have access to, this may take a few minutes!"
                        />
                        <Dropzone
                            display={knowledgeBaseId === undefined ? "block" : "none"}
                            maxSize={5000000}
                            loading={kbLoading}
                            accept={[
                                "image/png",
                                "image/jpeg",
                                "image/jpg",
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                "application/msword",
                                "application/pdf",
                                "text/plain",
                                "text/markdown",
                                "text/x-web-markdown",
                                "text/x-markdown",
                                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                "application/vnd.ms-powerpoint",
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                "application/vnd.ms-excel",
                            ]}
                            onReject={(event) => {
                                console.log(event)
                            }}
                            onDrop={async (event) => await onFileUpload(event[0])}
                            mt={8}
                            multiple={false}>
                            <Group justify="center" gap="xl" mih={150} style={{ pointerEvents: 'none' }}>
                                <Dropzone.Accept>
                                    <IoCheckmarkCircle
                                        style={{ width: rem(52), height: rem(52), color: 'teal' }}
                                    />
                                </Dropzone.Accept>
                                <Dropzone.Reject>
                                    <IconX
                                        style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
                                        stroke={1.5}
                                    />
                                </Dropzone.Reject>
                                <Dropzone.Idle>
                                    <IoDocument
                                        style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' }}
                                    />
                                </Dropzone.Idle>

                                <div>
                                    <Text size="xl" inline>
                                        Drag Documents You Want To Add
                                    </Text>
                                    <Text size="sm" c="dimmed" inline mt={7}>
                                        Attach a single file (Accepted files: DOCX, PDF, PPTX, HTML, TXT, XSLX)
                                    </Text>
                                </div>
                            </Group>
                        </Dropzone>
                        <Box mt={8}>
                            <Paper withBorder mih={32} p={12} display={(currKnowledgeBase !== undefined && !["ERROR", "COMPLETED"].includes(currKnowledgeBase.status)) ? "block" : "none"}>
                                <Flex>
                                    <Flex style={{ width: "100%" }} direction={"column"}>
                                        <Text fw={500} c="">Uploading <Code>{currKnowledgeBase?.srcFilename}</Code></Text>
                                        <Text c={"dimmed"} size="xs">
                                            {currKnowledgeBase?.status}
                                        </Text>
                                    </Flex>
                                    <Loader
                                        color="myColor" style={{ margin: "auto" }} />
                                </Flex>
                            </Paper>
                            <Alert
                                display={currKnowledgeBase?.status === "COMPLETED" ? "block" : "none"}
                                variant="light"
                                color="teal"
                                title={`Successfully uploaded ${currKnowledgeBase?.srcFilename}`}
                                icon={<IoCheckmark />}
                            />
                            <Alert
                                display={currKnowledgeBase?.status === "ERROR" ? "block" : "none"}
                                variant="light"
                                color="red"
                                title={<Text>There was an error with uploading {currKnowledgeBase?.srcFilename}</Text>}
                                icon={<IoClose />}
                            />
                        </Box>
                    </div>
                    <Flex mt={10} display={currKnowledgeBase && currKnowledgeBase.status === "COMPLETED" ? "block" : "none"}>
                        <div style={{ flexGrow: 1 }} />
                        <Group >
                            <Button
                                // loading={createIntegrationLoading}
                                variant="light"
                                color="myColor"
                                onClick={async () => {
                                    const response = await submitIntegration(form.values)
                                    if (response) {
                                        navigate("/integrations")
                                    }

                                }}>
                                Create Integration
                            </Button>
                            <Button color="myColor" onClick={async () => await submitIntegrationRedirectToAgent(form.values)}>Create Agent w/ Integration</Button>
                        </Group>
                    </Flex>
                </Stack>
            </form>
        </>)
}
export default OnwordDocsIntegrationForm