import React from 'react';
import { Flex, Grid, Modal, Overlay, Paper, ScrollArea, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import GoogleCalendarImage from '../../icons/GoogleCalendarImage';
import ApplicationLinkCard from '../card/ApplicationLinkCard';
import GoogleGmailImage from '../../icons/GoogleGmailImage';
import NotionImage from '../../icons/NotionImage';
import { IoCalculator, IoCalendar, IoDocument } from 'react-icons/io5';
import OutlookEmailImage from '../../icons/OutlookEmailImage';

interface IAddIntegrationModal {
    opened: boolean;
    close: () => void;
}

const AddIntegrationModal: React.FC<IAddIntegrationModal> = ({ opened, close }) => {


    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title={<>
                    <Text size="lg" fw={700} style={{ color: "#3a4bdd" }}>Add New Integration</Text>
                    <Text size="xs" fw={400} c="dimmed">Integrate application or documents into your agents</Text>
                </>}>
                <ScrollArea h={300} style={{ overflowX: "hidden", scrollbarWidth: "none" }}>
                    <Grid gutter={10} mt={10}>
                        <ApplicationLinkCard route="/integrations/docs" title="Your Documents" icon={<IoDocument style={{ width: 56, height: 56, margin: "auto", color: "gray" }} />} />
                        <ApplicationLinkCard route="/integrations/default" title="Calculator" icon={<IoCalculator style={{ width: 56, height: 56, margin: "auto", color: "gray" }} />} />
                        <ApplicationLinkCard route="/integrations/default" title="Date Counter" icon={<IoCalendar style={{ width: 56, height: 56, margin: "auto", color: "gray" }} />} />
                        <ApplicationLinkCard route="/integrations/google_calendar" title="Google Calendar" icon={<GoogleCalendarImage width={56} height={56} style={{ margin: "auto" }} />} />
                        <ApplicationLinkCard route="/integrations/google_gmail" title="Google Gmail" icon={<GoogleGmailImage width={56} height={56} style={{ margin: "auto" }} />} />
                        <ApplicationLinkCard route="/integrations/notion" title="Notion" icon={<NotionImage width={56} height={56} style={{ margin: "auto" }} />} />
                        <ApplicationLinkCard clickDisabled={true} route="" title="Outlook Email (Coming Soon)" icon={<OutlookEmailImage width={56} height={56} style={{ margin: "auto", filter: "grayscale(55%)", opacity: 0.5 }} />} />
                    </Grid>
                </ScrollArea>
            </Modal >
        </>
    );
}
export default AddIntegrationModal;