import React from 'react';
import { Grid, Paper, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

interface IApplicationLinkCard {
    title: string;
    icon: React.ReactNode;
    route: string
    clickDisabled?: boolean
}
const ApplicationLinkCard: React.FC<IApplicationLinkCard> = ({ title, icon, route, clickDisabled = false }) => {
    const navigate = useNavigate()
    return (
        <Grid.Col span={4} p={10} style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }} >
            <Paper p={10} h={120} w={120} withBorder style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                onClick={() => { if (!clickDisabled) navigate(route) }}
            >
                <>{icon}</>
                <Text size="xs" fw={700} c="dimmed" style={{ margin: "auto", textAlign: "center" }}>
                    {title}
                </Text>
            </Paper>
        </Grid.Col>
    );
}

export default ApplicationLinkCard;

