import { ActionIcon, Grid, Paper, Skeleton, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import IntegrationCard from "../card/IntegrationCard";
import { IoAdd } from "react-icons/io5";
import { useDisclosure } from "@mantine/hooks";
import AddIntegrationModal from "../modal/AddIntegrationModal";
import { IntegrationType } from "../../../types/integration/integration";
import { QueryObserverResult, RefetchOptions, useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { getAllIntegrations } from "../../../handler/integration/integration";

interface IIntegrationList {
  isLoading: boolean,
  integrations: IntegrationType[];
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<IntegrationType[], Error>>;
}

const IntegrationList: React.FC<IIntegrationList> = ({ isLoading, integrations, refetch }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    refetch();
  }, []);
  return (
    <>
      <Grid mt={32}>
        <Grid.Col span={3}>
          <Paper
            onClick={open}
            style={{
              border: "1px solid #E2E8F0",
              overflow: "hidden",
              // background: "#ebefff",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
            p={8}
            radius={8}
          >
            <IoAdd
              style={{
                marginTop: 8,
                width: 32,
                height: 32,
                margin: "auto",
                color: "#3a4bdd",
              }}
            />

            {/* <Text size='xs' c={"dimmed"} style={{ margin: "auto" }}>Add New Integration</Text> */}
          </Paper>
        </Grid.Col>
        {isLoading ? (
          <>
            {
              <>
                {Array.from({ length: 7 }, (_, index) => (
                  <Grid.Col span={3}>
                    <Skeleton height={100} />
                  </Grid.Col>
                ))}
              </>
            }
          </>
        ) : (
          <>
            {integrations?.map((integration: IntegrationType) => {
              return (
                <IntegrationCard
                  id={integration._id}
                  name={integration.name}
                  applicationName={integration.applicationName}
                  description={integration.description as string}
                />
              );
            })}
          </>
        )}
      </Grid>
      <AddIntegrationModal opened={opened} close={close} />
    </>
  );
};

export default IntegrationList;
