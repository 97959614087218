import axios from "axios";
import { CreateIntegrationType, IntegrationType } from "../../types/integration/integration";
import { MONOLITH_ROUTE } from "../routes";

export const createIntegration = async (token: string, integrationBody: CreateIntegrationType): Promise<IntegrationType> => {
    try {
        const response = await axios.post(
            `${MONOLITH_ROUTE}/integration`,
            integrationBody,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
        )
        if (response.status !== 201) {
            throw new Error("There was an error with creating integration")
        }
        return response.data as IntegrationType
    } catch (error) {
        throw new Error("There was an error with creating integration")
    }

}
export const getAllIntegrations = async (token: string): Promise<IntegrationType[]> => {
    try {
        const response = await axios.get(`${MONOLITH_ROUTE}/integrations`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        });
        if (response.status !== 200) {
            throw new Error("There was an error fetching integrations");
        }
        return response.data.sort((a: IntegrationType, b: IntegrationType) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()) as IntegrationType[];
    } catch (error) {
        throw new Error("There was an error fetching integrations");
    }
}


export const getIntegration = async (token: string, integrationId: string): Promise<IntegrationType> => {
    try {
        const response = await axios.get(`${MONOLITH_ROUTE}/integrations/${integrationId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )

        return response.data as IntegrationType
    } catch (error) {
        throw new Error("There was an error with fetching integration")
    }
}
export const deleteIntegration = async (token: string, integrationId: string): Promise<void> => {
    try {
        await axios.delete(`${MONOLITH_ROUTE}/integrations/${integrationId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
    } catch (error) {
        throw new Error("There was an error deleting integration")
    }

}