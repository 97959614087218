import axios from "axios";
import { MessageRunPostType } from "../../helper/chat/chatHelper";
import { ENGINE_ROUTE } from "../routes";

export type MessageType = {
  role: "system" | "assistant" | "user";
  content:
    | string
    | (
        | DocumentMessageContentInterface
        | TextMessageContentInterface
        | ImageMessageContentInterface
      )[];
  text?: string;
  runId?: string;
};

export interface TextMessageContentInterface {
  contentType: "text";
  text: string;
}

export interface DocumentMessageContentInterface {
  contentType: "document";
  filename: string;
  fileType: string;
  path: string;
  text?: string;
}

export interface ImageMessageContentInterface {
  contentType: "image";
  filename: string;
  fileType: "image/jpeg" | "image/jpg" | "image/png";
  path: string;
  text?: string;
}

export type KnowledgeBaseFileType = {
  bucketFilename: string;
  srcFilename: string;
  size: number;
  contentType: string;
  metadata: Record<string, any>;
  createdOn: string;
};

export const createAgentRun = (
  token: string,
  message: MessageRunPostType,
  refetch: (options?: any) => any,
  setMessageStack: React.Dispatch<React.SetStateAction<Record<string, any>[]>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  onDone?: () => void
) => {
  try {
    axios
      .post(`${ENGINE_ROUTE}/stream`, message, {
        headers: {
          Accept: "text/event-stream",
          Authorization: `Bearer ${token}`,
        },
        responseType: "stream",
        adapter: "fetch",
      })
      .then(async (response) => {
        const stream = response.data;
        // consume response
        const reader = stream.pipeThrough(new TextDecoderStream()).getReader();
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            refetch();
            setLoading(false);
            setMessageStack([]);
            if (onDone) onDone();
            break;
          }
          if (value.includes("data: ")) {
            const jsonString = value.replace("data: ", "");
            try {
              const dataArray = JSON.parse(jsonString);
              setMessageStack(dataArray);
            } catch (_) {}
          } // Update state with the received value
        }
      });
    // catch/etc.
  } catch (error) {
    console.error(error);
    throw new Error("There was an issue with sending message to agent engine");
  }
};
