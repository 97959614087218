import { useForm } from "@mantine/form";
import IntegrationHeader from "../../components/integration/header/IntegrationPermissionsList";
import {
  Accordion,
  Alert,
  Box,
  Button,
  Flex,
  MultiSelect,
  Stack,
  Textarea,
  TextInput,
  Title,
  Text,
  Slider,
  Select,
  SelectProps,
  Group,
  NumberInput,
  MultiSelectProps,
  Skeleton,
  Divider,
  ColorInput,
  ColorSwatch,
} from "@mantine/core";
import {
  IoAdd,
  IoCalculator,
  IoCalendar,
  IoChatbox,
  IoCog,
  IoColorFill,
  IoDocument,
  IoFlask,
  IoLink,
  IoSettings,
  IoWarning,
} from "react-icons/io5";
import { AiOutlineOpenAI } from "react-icons/ai";
import { TbBrandMeta } from "react-icons/tb";
import { IconCheck, IconCross, IconPaint, IconX } from "@tabler/icons-react";
import { SiAnthropic } from "react-icons/si";
import GoogleCalendarImage from "../../components/icons/GoogleCalendarImage";
import GoogleGmailImage from "../../components/icons/GoogleGmailImage";
import NotionImage from "../../components/icons/NotionImage";
import { useQuery } from "@tanstack/react-query";
import { getAllIntegrations } from "../../handler/integration/integration";
import { useAuth0 } from "@auth0/auth0-react";
import { IntegrationType } from "../../types/integration/integration";
import { AgentType, AgentPost } from "../../types/agent/agent";
import {
  createAgent,
  createAgentAndConversation,
} from "../../handler/agent/agent";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { validateAgentPost } from "../../validators/agent/validateAgentForm";
import classes from "./multiselectOption.module.css";

const LLMCompanyIcon: Record<string, React.ReactNode> = {
  openai: <AiOutlineOpenAI style={{ width: 24, height: 24 }} />,
  meta: <TbBrandMeta />,
  anthropic: <SiAnthropic style={{ width: 24, height: 24 }} />,
};

type ModelSelectDataType = {
  model: string;
  company: string;
  description: string;
};

const defaultIconProps = {
  width: 28,
  height: 28,
  color: "gray",
};

export const integrationIconMap: Record<string, React.ReactNode> = {
  GOOGLE_CALENDAR: <GoogleCalendarImage />,
  GOOGLE_GMAIL: <GoogleGmailImage />,
  NOTION: <NotionImage />,
  ONWORD_RAG: <IoDocument style={{ ...defaultIconProps }} />,
  ONWORD_DOCUMENT_SEARCH: <IoDocument style={{ ...defaultIconProps }} />,
  ONWORD_CALCULATOR: <IoCalculator style={{ ...defaultIconProps }} />,
  ONWORD_DATE_COUNTER: <IoCalendar style={{ ...defaultIconProps }} />,
};

export const renderSelectOption: SelectProps["renderOption"] = ({
  option,
  checked,
}) => {
  const value = JSON.parse(option.value);
  return (
    <Group flex="1" gap="xs">
      {LLMCompanyIcon[value.company]}
      <Flex direction={"column"}>
        <Text size="xs" fw={500} truncate>
          {option.label}
        </Text>
        <Text size="xs" c={"dimmed"} truncate>
          {value.description}
        </Text>
      </Flex>
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );
};
export const renderMultiSelectOption: MultiSelectProps["renderOption"] = ({
  option,
}) => {
  const { applicationName, description } = JSON.parse(option.value);
  return (
    <Group gap="sm">
      {integrationIconMap[applicationName]}
      <div>
        <Text size="sm" truncate>
          {option.label}
        </Text>
        <Text size="xs" opacity={0.5} truncate>
          {description}
        </Text>
      </div>
    </Group>
  );
};

interface ICreateAgentForm {
  models: SelectRenderType[];
  defaultIntegrations?: IntegrationType[];
}

type SelectRenderType = {
  label: string;
  value: string;
  disabled?: boolean;
};

type AgentFormValuesType = {
  name: string;
  systemPrompt: string;
  integrations: string[];
  model: string;
  maxIterations: number;
  temperature: number;
  agentColor: string;
};

const CreateAgentForm: React.FC<ICreateAgentForm> = ({
  models,
  defaultIntegrations = [],
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const defaultIntegrationStringify: string[] = defaultIntegrations.map(
    (item) => JSON.stringify(item)
  );

  const form = useForm({
    initialValues: {
      name: "",
      systemPrompt: "",
      integrations: defaultIntegrationStringify as string[],
      model: models[0].value,
      maxIterations: 30,
      temperature: 0.1,
      metadata: {},
      agentColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    },
  });
  const { data, isLoading, refetch } = useQuery({
    queryKey: [],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const integrationsResponse = await getAllIntegrations(token);
      return integrationsResponse;
    },
  });
  const onSubmit = async (
    values: AgentFormValuesType
  ): Promise<AgentType | undefined> => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const integrations: string[] = values.integrations.map(
        (item) => JSON.parse(item)._id
      );
      const tools: string[] = values.integrations.flatMap(
        (item) => JSON.parse(item).tools
      );
      const model: ModelSelectDataType = JSON.parse(values.model);
      const { agentColor, ...filteredValues } = values;
      const agentPostData: AgentPost = {
        ...filteredValues,
        integrations,
        tools,
        model: model.model,
        metadata: { agentColor: values.agentColor },
      };
      validateAgentPost(agentPostData);
      setLoading(false);
      const response = await createAgent(token, agentPostData);
      navigate("/agents");
      return response as AgentType;
    } catch (error: unknown) {
      console.error(error);
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      notifications.show({
        title: "There was an error creating agent",
        message: `Error: ${errorMessage}`,
        color: "red",
        icon: <IconX />,
      });
      setLoading(false);
    }
  };
  const onCreateAgentAndConversation = async (
    values: AgentFormValuesType
  ): Promise<string | undefined> => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const integrations: string[] = values.integrations.map(
        (item) => JSON.parse(item)._id
      );
      const tools: string[] = values.integrations.flatMap(
        (item) => JSON.parse(item).tools
      );
      const model: ModelSelectDataType = JSON.parse(values.model);
      const { agentColor, ...filteredValues } = values;
      const agentPostData: AgentPost = {
        ...filteredValues,
        integrations,
        tools,
        model: model.model,
        metadata: { agentColor: values.agentColor },
      };
      setLoading(false);

      const response = await createAgentAndConversation(token, agentPostData);
      notifications.show({
        title: (
          <Text c="myColor">{`Successfully created ${form.values.name}`}</Text>
        ),
        message: `Your ${form.values.name} has been created and is ready for use`,
        autoClose: 3000,
        color: "teal",
        icon: <IconCheck />,
      });
      navigate(`/chats/${response._id}`);
      return response._id;
    } catch (error) {
      console.error(error);
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      notifications.show({
        title: (
          <Text c="myColor">{`Failed to create ${form.values.name}`}</Text>
        ),
        message: `Error: ${errorMessage}`,
        autoClose: 3000,
        color: "red",
        icon: <IconX />,
      });
    }
  };
  return (
    <Box maw={600} m={"auto"} mt={25}>
      <IntegrationHeader icon={<></>} title="Create Agent" />

      <Stack gap={25} mt={12}>
        <Flex direction="column" gap={4}>
          <TextInput
            label={"Name"}
            required
            style={{ color: "#3a4bdd" }}
            placeholder="Example: Calendar Agent"
            withAsterisk
            description="Give your agent a name that describes it's role"
            key={form.key("name")}
            {...form.getInputProps("name")}
          />
          <Textarea
            label="System Prompt"
            required
            withAsterisk
            description="Give your agent descriptions on how it should function (example: Use calendar tool to...)"
            placeholder="You are a helpful assistant..."
            style={{ color: "#3a4bdd" }}
            key={form.key("systemPrompt")}
            {...form.getInputProps("systemPrompt")}
            rows={5}
          />
        </Flex>
        <Skeleton visible={isLoading}>
          <MultiSelect
            classNames={classes}
            leftSection={<IoLink />}
            searchable
            maxValues={5}
            color="myColor"
            disabled={isLoading}
            label={"Add Integration"}
            style={{ color: "#3a4bdd" }}
            description="Allow agents to perform actions on your apps"
            placeholder="Example: Work Calendar"
            renderOption={renderMultiSelectOption}
            data={data?.map((integration: IntegrationType) => {
              return {
                label: integration.name,
                value: JSON.stringify(integration),
              };
            })}
            key={form.key("integrations")}
            {...form.getInputProps("integrations")}
            nothingFoundMessage={
              <Button
                leftSection={<IoAdd />}
                onClick={() => navigate("/integrations")}
                color="myColor"
                variant="light"
              >
                Create New Integration
              </Button>
            }
            defaultValue={defaultIntegrationStringify}
          />
        </Skeleton>
        <Accordion>
          <Accordion.Item key={"model-design"} value={"model-design"}>
            <Accordion.Control
              style={{ color: "#3a4bdd" }}
              icon={<ColorSwatch color={form.values.agentColor} withShadow />}
            >
              Agent Design
            </Accordion.Control>
            <Accordion.Panel>
              <ColorInput
                label={
                  <Text color="myColor" fw={500} size="sm">
                    Agent Color
                  </Text>
                }
                description="Give your agent a color to easily identify it"
                placeholder="#FFFFFF"
                defaultValue={form.values.agentColor}
                key={form.key("agentColor")}
                {...form.getInputProps("agentColor")}
              />
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item key={"model-settings"} value={"model-settings"}>
            <Accordion.Control
              style={{ color: "#3a4bdd" }}
              icon={
                <IoFlask style={{ width: 24, height: 24, marginLeft: 3 }} />
              }
            >
              Advanced Settings
            </Accordion.Control>
            <Accordion.Panel>
              <Divider my={8} />
              <Alert icon={<IoWarning />} color="yellow" variant="light">
                <Text size="xs" fw={500} mt={0} c="">
                  Warning!
                </Text>
                <Text size="xs" c={"dimmed"} mt={0}>
                  Changing these parameters can result in unexpected agent
                  behaviour
                </Text>
              </Alert>
              <Stack gap={16} mt={12}>
                <Select
                  label={
                    <Text fw={500} size="sm" style={{ color: "#3a4bdd" }}>
                      Model Preference
                    </Text>
                  }
                  description="Choice of LLM that agent uses, this may change response quality"
                  renderOption={renderSelectOption}
                  defaultChecked={true}
                  defaultValue={form.values.model}
                  data={models}
                  key={form.key("model")}
                  {...form.getInputProps("model")}
                />
                <Stack gap={2}>
                  <Flex gap={2} direction={"column"}>
                    <Text fw={500} size="sm" style={{ color: "#3a4bdd" }}>
                      Model Temperature
                    </Text>
                    <Text size="xs" c={"dimmed"}>
                      Controls the randomness of agent's response
                    </Text>
                  </Flex>
                  <Slider
                    color="myColor"
                    min={0}
                    max={1}
                    step={0.0005}
                    defaultValue={form.values.temperature}
                    key={form.key("temperature")}
                    {...form.getInputProps("temperature")}
                  />
                </Stack>
                <NumberInput
                  label={
                    <Text fw={500} size="sm" style={{ color: "#3a4bdd" }}>
                      Agent Maximum Iterations
                    </Text>
                  }
                  description="Maximum number of iterations agent has during chain of thought, this will affect the number of actions an agent can perform on a single prompt"
                  min={10}
                  max={50}
                  defaultValue={form.values.maxIterations}
                  key={form.key("maxIterations")}
                  {...form.getInputProps("maxIterations")}
                />
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Stack>
      <Stack mt={8}>
        <Text c="dimmed" size="xs" mt={0}>
          Disclaimer: Large language models can sometimes produce incorrect or
          misleading information, known as hallucinations.
        </Text>
        <Flex gap={8}>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            variant="light"
            color="myColor"
            onClick={async () => {
              await onSubmit(form.values);
            }}
            loading={loading}
          >
            Create Agent
          </Button>
          <Button
            leftSection={<IoChatbox />}
            color="myColor"
            onClick={async () => {
              await onCreateAgentAndConversation(form.values);
            }}
            loading={loading}
          >
            Create Agent & Chat
          </Button>
        </Flex>
      </Stack>
    </Box>
  );
};
export default CreateAgentForm;

function getAccessTokenSilently() {
  throw new Error("Function not implemented.");
}
