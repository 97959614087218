import axios from "axios";
import { MONOLITH_ROUTE } from "../routes"


export type KnowledgeBaseDataType = {
    status: "COMPLETED" | "IN_PROGRESS" | "ERROR" | "NOT_STARTED";
    bucketFilename: string;
    srcFilename: string;
    contentType: string;
    size: number;
    createdOn: string;
    createdBy: string;
    metadata: Record<string, any>;
}

export const getKnowledgeBase = async (knowledgeBaseId: string, token: string): Promise<KnowledgeBaseDataType> => {
    try {
        const response = await axios.get(
            `${MONOLITH_ROUTE}/knowledge_base/${knowledgeBaseId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
        );
        return response.data as KnowledgeBaseDataType;
    } catch (error) {
        throw new Error("There was an error checking the knowledge base status");
    }
}
