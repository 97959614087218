import { Box, Title, Text, Center, Flex, TextInput, em, Paper } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";
import { IoSearch } from "react-icons/io5";

interface IIntegrationSection {
    setSearch: React.Dispatch<React.SetStateAction<string>>;
}


const IntegrationsSection: React.FC<IIntegrationSection> = ({ setSearch }) => {
    const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
    return (
        <Box>
            <Center w={"100%"} mt={16}>
                <Flex direction={"column"} align={"center"} >

                    <Title order={2} style={{ color: '#3a4bdd' }}>Your Integrations</Title>
                    <Text size="sm" color="dimmed">View and manage all the integrations you have set up</Text>
                    <Paper shadow="sm">
                        <TextInput
                            onChange={(event) => setSearch(event.target.value)}
                            w={isMobile ? "100%" : 600}
                            leftSection={<IoSearch />}
                            styles={{ input: { borderColor: "rgb(223,226,230)" } }}
                            placeholder="Search Integrations" mt={10} />
                    </Paper>
                </Flex>

            </Center>
        </Box >
    );
};

export default IntegrationsSection;
