import axios from "axios"
import { MONOLITH_ROUTE } from "../routes"

export const getAiGeneratedTitle = async (token: string, conversationId: string): Promise<void> => {
    try {
        const response = await axios.put(`${MONOLITH_ROUTE}/rewrite/conversation/${conversationId}`, undefined, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if (response.status !== 200) {
            throw new Error("The rewriting was not successful")
        }

    } catch (error) {
        throw new Error("there was an error generating conversation title")
    }
}