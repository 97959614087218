import NotionIntegrationForm from "./NotionIntegrationForm"

const NotionIntegrationPage = () => {
    return (<>
        <NotionIntegrationForm actions={[
            {
                name: "Get Page",
                description: "Gets full Notion Page content",
                action: "NOTION_PAGE_GET"
            },
            {
                name: "Search Database",
                description: "Searches Notion Database based on text",
                action: "NOTION_DATABASE_SEARCH"
            },
        ]} />
    </>)
}

export default NotionIntegrationPage