import axios from "axios";
import { GoogleCalendarDataType } from "../../../../types/integration/action/GoogleCalendar/googleCalendar";
import { TOOL_MONOLITH_ROUTE } from "../../../routes";

export const getGoogleCalendarList = async (token: string, permissionId: string): Promise<GoogleCalendarDataType[]> => {
    try {
        const response = await axios.get(
            `${TOOL_MONOLITH_ROUTE}/google_calendar/calendars`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    permission_id: permissionId
                }
            }
        );
        return response.data["items"] as GoogleCalendarDataType[];
    } catch (error) {
        throw new Error("Error getting google calendar list");
    }
}