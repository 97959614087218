import {
  Button,
  Flex,
  Group,
  Menu,
  Modal,
  rem,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import {
  IconArrowsLeftRight,
  IconCheck,
  IconMessageCircle,
  IconPhoto,
  IconSearch,
  IconSettings,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { AgentType } from "../../../types/agent/agent";
import { IoPencil, IoTrashBin } from "react-icons/io5";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteAgent } from "../../../handler/agent/agent";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  ConversationType,
  createConversation,
} from "../../../handler/conversation/conversation";

interface IAgentDropdownMenu {
  agent: AgentType;
  children: React.ReactNode | React.ReactNode[];
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<AgentType[], Error>>;
}

const AgentDropdownMenu: React.FC<IAgentDropdownMenu> = ({
  agent,
  children,
  refetch,
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const form = useForm({
    initialValues: {
      deleteConfirmText: "",
    },
  });
  const onConfirm = async () => {
    try {
      if (form.values.deleteConfirmText !== agent.name) {
        throw new Error(`Type '${agent.name}' to confirm deletion`);
      }
      const token = await getAccessTokenSilently();
      await deleteAgent(token, agent._id);
      refetch();
      notifications.show({
        color: "teal",
        icon: <IconCheck />,
        title: "Successfully Deletion",
        message: `${agent.name} was deleted.`,
      });
      form.reset();
      close();
    } catch (error) {
      notifications.show({
        color: "red",
        icon: <IconX />,
        title: "There was an error with deleting agent",
        message:
          error instanceof Error ? error.message : "An unknown error occurred",
      });
    }
  };
  const onCreateConversation = async () => {
    try {
      const token = await getAccessTokenSilently();
      const conversation: ConversationType = await createConversation(
        token,
        `New Chat w/ ${agent.name}`,
        agent._id
      );
      navigate(`/chats/${conversation._id}`);
      close();
      notifications.show({
        title: "Successfully created chat",
        message: `Created chat with ${agent.name}`,
        autoClose: 3000,
        color: "teal",
        icon: <IconCheck />,
      });
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";
      notifications.show({
        title: "Failed to create chat",
        message: `Error: ${errorMessage}`,
        autoClose: 3000,
        color: "red",
        icon: <IconX />,
      });
    }
  };
  return (
    <>
      <Menu shadow="md">
        <Menu.Target>{children}</Menu.Target>

        <Menu.Dropdown pr={6}>
          <Menu.Label>Manage Your Agent</Menu.Label>

          <Menu.Item
            leftSection={
              <IconMessageCircle style={{ width: rem(14), height: rem(14) }} />
            }
            onClick={async () => {
              await onCreateConversation();
            }}
          >
            Chat with {agent.name}
          </Menu.Item>

          <Menu.Divider />

          <Menu.Label>Danger zone</Menu.Label>
          <Menu.Item
            onClick={open}
            color="red"
            leftSection={
              <IoTrashBin style={{ width: rem(14), height: rem(14) }} />
            }
          >
            Delete {agent.name}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <Modal opened={opened} onClose={close} centered withCloseButton={false}>
        <Stack gap={25}>
          <TextInput
            c="red"
            label={`Delete ${agent.name}?`}
            description="Deleting this agent is permanent & will also delete its associated chats. However, linked actions and integrations will not change."
            placeholder={`Type '${agent.name}'`}
            key={form.key("deleteConfirmText")}
            {...form.getInputProps("deleteConfirmText")}
          />
          <Flex>
            <div style={{ flexGrow: 1 }} />
            <Group align="right">
              <Button onClick={close} color="gray" variant="subtle">
                Cancel
              </Button>
              <Button
                onClick={onConfirm}
                color="red"
                leftSection={<IoTrashBin />}
              >
                Confirm
              </Button>
            </Group>
          </Flex>
        </Stack>
      </Modal>
    </>
  );
};
export default AgentDropdownMenu;
