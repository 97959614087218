import {
  ActionIcon,
  Box,
  Code,
  Divider,
  Flex,
  Grid,
  Paper,
  rem,
  Text,
} from "@mantine/core";
import { CodeHighlight } from "@mantine/code-highlight";
import { MessageType } from "../../../handler/message/message";
import Markdown from "react-markdown";
import ChatFilePill from "../files/ChatFilePill";
import { useEffect, useRef, useState } from "react";
import {
  IconArrowRight,
  IconArrowRightTail,
  IconBrain,
  IconClipboardText,
} from "@tabler/icons-react";

interface IChatMessageBox {
  message: MessageType;
  isLast: boolean;
  ref: React.RefObject<HTMLDivElement>;
  onOpenRunDisplay: () => void;
  setRunId: React.Dispatch<React.SetStateAction<string | undefined>>;
}
export const useTypingEffect = (
  text: string,
  duration: number,
  isTypeByLetter = false,
  onDone?: () => void,
  ref?: React.RefObject<HTMLDivElement>
) => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const items = isTypeByLetter ? text.split("") : text.split(" ");

  useEffect(() => {
    setCurrentPosition(0);
  }, [text]);

  useEffect(() => {
    if (currentPosition >= items.length) {
      if (onDone) onDone();
      return;
    }

    const intervalId = setInterval(() => {
      setCurrentPosition((prevPosition: number) => prevPosition + 1);
      ref?.current?.scrollIntoView({ behavior: "smooth" });
    }, duration);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentPosition, items, duration]);

  return items.slice(0, currentPosition).join(isTypeByLetter ? "" : " ");
};

const ChatMessageBoxAssistant: React.FC<IChatMessageBox> = ({
  message,
  isLast,
  ref,
  onOpenRunDisplay,
  setRunId,
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const textToShow = useTypingEffect(
    message.text as string,
    1,
    true,
    () => { },
    textRef
  );

  return (
    <Box style={{ maxWidth: "100%" }}>
      <Flex direction="column">
        <div
          style={{
            fontSize: rem(14),
            textAlign: "justify",
            wordWrap: "break-word",
          }}
        >
          <Markdown
            components={{
              code(props) {
                const { children, className, node, ...rest } = props;
                const match = /language-(\w+)/.exec(className || "");
                if (match) {
                  const language = match[1];
                  return (
                    <CodeHighlight
                      code={String(children)}
                      language={language}
                    />
                  );
                } else {
                  return <Code>{children}</Code>;
                }
              },
            }}
          >
            {message.text}
          </Markdown>
        </div>

        <Flex px={8} gap={2}>
          <div style={{ flexGrow: 1 }} />
          <ActionIcon
            variant="subtle"
            color="gray"
            size={"sm"}
            onClick={() =>
              navigator.clipboard.writeText(message.text as string)
            }
          >
            <IconClipboardText />
          </ActionIcon>
          <ActionIcon
            disabled={message.runId === undefined}
            variant="subtle"
            color="gray"
            size={"sm"}
            onClick={() => {
              setRunId(message.runId as string);
              onOpenRunDisplay();
            }}
          >
            <IconBrain />
          </ActionIcon>
        </Flex>
      </Flex>
      <div ref={textRef} />
    </Box>
  );
};

export const ChatMessageBoxUser: React.FC<IChatMessageBox> = ({ message }) => {
  if (Array.isArray(message.content)) {
    const text = message.content.filter(
      (part) => part.contentType === "text"
    )[0];
    return (
      <Box p={8}>
        <Flex direction="column">
          <div
            style={{ textAlign: "right", marginRight: 12, fontSize: rem(14) }}
          >
            <Markdown>{text.text}</Markdown>
          </div>
          {/* <Text c="dimmed" size="xs">files:</Text> */}
          <Grid style={{ width: "100%" }}>
            {message.content.map((messageContent, index) => {
              if (messageContent.contentType !== "text") {
                return (
                  <ChatFilePill
                    filename={messageContent.filename}
                    contentType={messageContent.contentType}
                  />
                );
              } else {
                return <></>;
              }
            })}
          </Grid>
        </Flex>
      </Box>
    );
  } else {
    return (
      <Box style={{ maxWidth: "100%" }}>
        <Flex direction="column">
          <div
            style={{ textAlign: "right", marginRight: 12, fontSize: rem(14) }}
          >
            <Markdown>{message.text}</Markdown>
          </div>
        </Flex>
      </Box>
    );
  }
};

const ChatMessageBox: React.FC<IChatMessageBox> = ({
  onOpenRunDisplay,
  message,
  isLast,
  ref,
  setRunId,
}) => {
  return (
    <>
      {message.role === "assistant" ? (
        <ChatMessageBoxAssistant
          ref={ref}
          isLast={isLast}
          message={message}
          onOpenRunDisplay={onOpenRunDisplay}
          setRunId={setRunId}
        />
      ) : (
        <></>
      )}
      {message.role === "user" ? (
        <ChatMessageBoxUser
          ref={ref}
          isLast={isLast}
          message={message}
          setRunId={setRunId}
          onOpenRunDisplay={onOpenRunDisplay}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ChatMessageBox;
