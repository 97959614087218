import { Timeline, Text, Skeleton } from "@mantine/core";
import {
  IconGavel,
  IconGitBranch,
  IconRobot,
  //   IconGitCommit,
  //   IconGitPullRequest,
  //   IconMessageDots,
} from "@tabler/icons-react";
import { Run, RunAction } from "../../handler/run/run";
import { IoHammer, IoPerson } from "react-icons/io5";
import { RiRobot2Fill } from "react-icons/ri";
import { CodeHighlight } from "@mantine/code-highlight";

interface IRunDisplayDrawer {
  run: Run | undefined;
}
const iconMap: Record<string, React.ReactNode> = {
  agent: <RiRobot2Fill />,
  user: <IoPerson />,
  action: <IoHammer />,
};

const RunDisplayDrawer: React.FC<IRunDisplayDrawer> = ({ run }) => {
  const getTimeAgo = (isoString: string): string => {
    const now = new Date();
    const past = new Date(isoString);
    const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);

    const years = Math.floor(diffInSeconds / (3600 * 24 * 365));
    const months = Math.floor(
      (diffInSeconds % (3600 * 24 * 365)) / (3600 * 24 * 30)
    );
    const days = Math.floor((diffInSeconds % (3600 * 24 * 30)) / (3600 * 24));
    const hours = Math.floor((diffInSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    if (years > 0) {
      return `${years} years ago`;
    } else if (months > 0) {
      return `${months} months ago`;
    } else if (days > 0) {
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} minutes ago`;
    } else {
      return `${seconds} seconds ago`;
    }
  };
  return (
    <>
      {run === undefined ? (
        <Skeleton visible style={{ width: "100%", height: 32 }} />
      ) : (
        <Timeline
          active={run.actions.length}
          bulletSize={24}
          lineWidth={2}
          color="myColor"
        >
          {run.actions.map((runAction) => {
            return (
              <Timeline.Item
                bullet={iconMap[runAction.type]}
                title={runAction.title}
              >
                {runAction.text ? (
                  <Text c="dimmed" size="sm" truncate>
                    {runAction.type === "user" ? (
                      <>{runAction.text}</>
                    ) : (
                      <code>{runAction.text}</code>
                    )}
                  </Text>
                ) : (
                  <></>
                )}
                {runAction.args ? (
                  <CodeHighlight
                    lang="json"
                    code={JSON.stringify(runAction.args)}
                  ></CodeHighlight>
                ) : (
                  <></>
                )}
                <Text size="xs" mt={4}>
                  {getTimeAgo(runAction.startedAt)}
                </Text>
              </Timeline.Item>
            );
          })}
        </Timeline>
      )}
    </>
  );
};
export default RunDisplayDrawer;
