import { DocumentMessageContentInterface, ImageMessageContentInterface, TextMessageContentInterface } from "../../handler/message/message"
import { FileMessageType } from "../../pages/chat/ChatPage"

export type MessageRunPostType = {
    agentId: string
    conversationId: string
    inputMessage: InputMessageType
}

export type InputMessageType = {
    role: "user"
    content: string | (DocumentMessageContentInterface | TextMessageContentInterface | ImageMessageContentInterface)[]
}



export const serialiseMessage = (agentId: string, conversationId: string, text: string, files: FileMessageType[]): MessageRunPostType => {
    const serialisedFiles: Record<string, any>[] = files.map((file) => {
        return {
            filename: file.srcFilename,
            fileType: file.fileType,
            contentType: file.contentType,
            path: file.bucketFilename,
            text: undefined
        }
    })
    const serialisedTextMessage: TextMessageContentInterface = { contentType: "text", text }
    return { agentId, conversationId, inputMessage: { role: "user", content: [...serialisedFiles as (DocumentMessageContentInterface | TextMessageContentInterface | ImageMessageContentInterface)[], serialisedTextMessage as TextMessageContentInterface] } }
}