import React, { useEffect } from "react";

// core styles are required for all packages
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/code-highlight/styles.css";
import { useAuth0 } from "@auth0/auth0-react";
import { Anchor, Button, Divider, Paper, Stack, Text } from "@mantine/core";
import OnwordLogo from "./assets/logo.svg"


function App() {
  const { loginWithRedirect } = useAuth0();

  return (
    <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
      <Paper withBorder shadow="md" p={25} style={{ margin: "auto" }}>
        <Stack gap={18}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <img src={OnwordLogo} alt="Onword Logo" width={32} height={32} style={{ margin: "auto" }} />
            <p className="shimmer" style={{ fontSize: 24, fontWeight: 600, marginBottom: 0 }}>
              Onword AI
            </p>
            <Text c={"dimmed"} size="xs">
              AI Actions on Your Word
            </Text>

          </div>
          <Divider />
          <Button color="myColor" onClick={() => loginWithRedirect()} style={{"width":"100%"}}>
            Sign Up | Login
          </Button>
          <Text size="xs" c={"dimmed"}>
          By signing up, you agree to Onword's <Anchor c="myColor" href="https://onword.ai/blog/terms-of-use">terms of service</Anchor> & <Anchor c="myColor" href="https://onword.ai/blog/our-privacy-policy">privacy policy</Anchor>.
          </Text>
        </Stack>
      </Paper>
    </div>
  );
}

export default App;
