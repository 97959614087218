import {
  ActionIcon,
  Box,
  Button,
  Code,
  ColorSwatch,
  Divider,
  Flex,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllAgents } from "../../handler/agent/agent";
import { useAuth0 } from "@auth0/auth0-react";
import { AgentType } from "../../types/agent/agent";
import {
  IoAdd,
  IoChatbubbleEllipses,
  IoEllipsisVertical,
} from "react-icons/io5";
import AgentCard from "../../components/agent/AgentCard";
import { RiRobot2Fill } from "react-icons/ri";
import GettingStartedPanel from "../../components/tutorial/GettingStartedPanel";

const AgentsPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [agents, setAgents] = useState<AgentType[]>([]);
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useQuery({
    queryKey: [],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const response = await getAllAgents(token);
      setAgents(response);
      return response;
    },
  });
  return (
    <>
      <Stack gap={25}>
        <GettingStartedPanel />
        <Flex direction={"row"}>
          <Flex style={{ flexGrow: 1 }} direction={"column"}>
            <Text size="lg" fw={600} c="myColor">
              Agents
            </Text>
            <Text c="dimmed" size="xs">
              Manage & create agents to connect with your apps
            </Text>
          </Flex>
          <Button
            style={{ marginBlock: "auto" }}
            rightSection={<IoAdd />}
            onClick={() => navigate("/agents/create")}
            color="myColor"
          >
            Add Agent
          </Button>
        </Flex>
        <Divider />
        <Grid style={{ height: "100%" }}>
          {isLoading === true ? (
            <>
              {Array.from({ length: 8 }, (_, index) => (
                <Grid.Col span={3}>
                  <Skeleton height={66} />
                </Grid.Col>
              ))}
            </>
          ) : (
            <>
              {
                <>
                  {agents.length > 0 ? (
                    agents?.map((agent) => {
                      return <AgentCard agent={agent} refetch={refetch} />;
                    })
                  ) : (
                    <Paper
                      m={8}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                      withBorder
                      p={25}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "auto",
                          gap: 10,
                        }}
                      >
                        <RiRobot2Fill
                          color="lightgray"
                          style={{ margin: "auto", width: 32, height: 32 }}
                        />
                        <Text c="dimmed" size="sm">
                          Start creating an Agent by clicking 'Add Agent'
                        </Text>
                      </div>
                    </Paper>
                  )}
                </>
              }
            </>
          )}
        </Grid>
      </Stack>
    </>
  );
};

export default AgentsPage;
