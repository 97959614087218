import { Button, Loader, Text } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AddAccountIntegrationRedirectPage = () => {
    const [value, setValue] = useLocalStorage({
        key: 'onwordExistingRedirect'
    });
    const navigate = useNavigate()
    useEffect(() => {
        console.log(value)
        if (value && value !== "") {

            navigate(value)
            setValue("")
        }
    }, [value])
    return (<div
        style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
        }}
    >
        <div style={{ margin: "auto", alignItems: "center", display: "flex", flexDirection: "column", gap: 25 }} >
            <Loader color="myColor" style={{ margin: "auto" }} />
            <div style={{ textAlign: "center" }}>
                <Text c="myColor" fw={500} size="md">Redirecting to Integration</Text>
                <Text c="dimmed" fw={500} size="xs">Give us a second...If you're not redirected in the next 30 seconds, click on the button below</Text>
                <Button mt={25}
                    color="myColor"
                    onClick={() => {
                        navigate("/")
                    }}
                >
                    Back to Home
                </Button>
            </div>
        </div>
    </div>)
}

export default AddAccountIntegrationRedirectPage